var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_4"},[_c('div',{staticClass:"utilityPage"},[_c('img',{staticClass:"bg_utility",attrs:{"src":require("../assets/bg_utility.png"),"alt":""}}),_vm._m(0),_c('div',{staticClass:"boxContent"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"item item_1",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(1)]),_c('div',{staticClass:"item item_2",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(2)]),_c('div',{staticClass:"item item_3",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(3)])]),_c('div',{staticClass:"imgMain",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(4)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"item item_4",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(5)]),_c('div',{staticClass:"item item_5",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(6)]),_c('div',{staticClass:"item item_6",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(7)])])]),_c('div',{staticClass:"lastLine"},[_c('div',{staticClass:"line item_7",on:{"mousemove":_vm.handleMouseMove}},[_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("NFT Utility")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("Lifetime Premium Access To Crypto Trading App By Zyncas for "),_c('span',{staticClass:"cyanBrown"},[_vm._v("Genesis NFT owner")])]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Enjoy permanent access to premium features and content in Signals application at no extra cost.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("6 Months Premium Access To Crypto Trading App By Zyncas for "),_c('span',{staticClass:"cyanBlue"},[_vm._v("Zodiac Arena NFT owner")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("$ZCS Token Airdrop")]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Receive ZCS tokens directly as part of an exclusive airdrop.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardImg"},[_c('img',{attrs:{"src":require("../assets/img_utility.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("$ZCS Private Sales Whitelist")]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Gain access to ZCS private sales with a guaranteed whitelist spot.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("Staking & Lending")]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Utilize your NFTs for staking, lending, or selling on OpenSea for profit.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line"},[_c('div',{staticClass:"content"},[_c('h2',[_vm._v("DAO Governance & Voting")]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Slash fees, boost staking and referral rewards and enjoy cashback effortlessly.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h2',[_vm._v("Exclusive Access To Auto"),_c('br'),_vm._v(" Trading Bot By Zyncas")]),_c('div',{staticClass:"decription"},[_c('p',[_vm._v("Benefit from a built-in auto trading bot for 24/7 automated trading strategies.")])])])
}]

export { render, staticRenderFns }