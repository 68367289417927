import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDEE1USfyjqze0W2dSZOntxUjp-0rvFYhE",
    authDomain: "signals-61284.firebaseapp.com",
    databaseURL: "https://signals-61284.firebaseio.com",
    projectId: "signals-61284",
    storageBucket: "signals-61284.appspot.com",
    messagingSenderId: "727577331880",
    appId: "1:727577331880:web:bb39906b254568a8d8da9d",
    measurementId: "G-Y4LSNQCLQF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
