<template>
    <div class="section_2">
        <div class="content_left">
            <img class="icon" src="../assets/icon.png" alt="">
            <div class="title">
                <h2>About story</h2>
            </div>
            <div class="imgContent" @mousemove="moveImage">
                <img class="open" src="../assets/story.jpg" alt="">
            </div>
        </div>
        <div class="content_right">
            <div class="edSlide">
                <slick ref="slick" :options="slickOptionsAbout">
                    <div class="imgSlide" v-for="item in itemAbout" :key="item.id">
                        <img :src="require('../assets/' + item.imgUrl)" alt="">
                    </div>
                </slick>
            </div>
            <div class="content">
                <p>“One step into the Market, one step into the Fightzone”. Anyone who has access to the Zodiac Arena can choose a gladiator, as known as a “Zodiac Avatar”, that represent their real life Zodiac Animal.</p>
                <a href="https://app.signals.zyncas.com/zodiac-arena" target="_blank">Buy NFT</a>
                <img src="../assets/icon.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    export default {
        components: {
            Slick,
        },
        data() {
            return {
                index: 0,
                options: {
                    licenseKey: 'YOUR_KEY_HERE',
                    scrollingSpeed: 1500,
                    responsiveWidth: 992,
                    // setAllowScrolling: false,
                    autoScrolling: true,
                    // scrollBar: true,
                    easingcss3: 'ease',
                    onLeave: this.onLeave,
                },
                slickOptionsAbout: {
                    slidesToShow: 7,
                    dots: false,
                    autoplay: true,
                    infinite: true,
                    autoplaySpeed: 1,
                    cssEase: 'linear',
                    pauseOnHover:false,
                    speed: 4000,
                    prevArrow: false,
                    nextArrow: false,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 1440,
                            settings: {
                                slidesToShow: 6,
                            },
                        },
                        
                    ]
                },
                itemAbout:[
                    {
                        id: 1,
                        imgUrl: 'img_1.png',
                    },
                    {
                        id: 2,
                        imgUrl: 'img_2.png',
                    },
                    {
                        id: 3,
                        imgUrl: 'img_3.png',
                    },
                    {
                        id: 4,
                        imgUrl: 'img_4.png',
                    },
                    {
                        id: 5,
                        imgUrl: 'img_5.png',
                    },
                    {
                        id: 6,
                        imgUrl: 'img_6.png',
                    },
                    {
                        id: 7,
                        imgUrl: 'img_7.png',
                    },
                    {
                        id: 8,
                        imgUrl: 'img_8.png',
                    },
                    {
                        id: 9,
                        imgUrl: 'img_9.png',
                    },
                    {
                        id: 10,
                        imgUrl: 'img_10.png',
                    },
                    {
                        id: 11,
                        imgUrl: 'img_11.png',
                    },
                    {
                        id: 12,
                        imgUrl: 'img_12.png',
                    },
                ],
            };
        },
        methods:{
            moveImage(event){
                const bg = document.querySelector('.imgContent img');
                const windowWidth = window.innerWidth / 7;
                const windowHeight = window.innerHeight / 7;
                const mouseX = event.clientX / windowWidth;
                const mouseY = event.clientY / windowHeight;
                bg.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
            },
            
            next() {
                this.$refs.slick.next();
            },
            prev() {
                this.$refs.slick.prev();
            },
            reInit() {
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .section_2{
        display: flex;
        flex-wrap: nowrap;
        height: 100vh;
        align-items: center;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        .content_left{
            text-align: center;
            width: 45%;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            border-right: 1px solid rgba(0, 0, 0, 0.5);
            position: relative;
            >img.icon{
                position: absolute;
                left: 40px;
                bottom: 120px;
                transform: rotate(90deg);
            }
            .title{
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
                margin-top: 40px;
                h2{
                    margin: 10px 0 30px;
                    font-family: 'Neboma Sporte';
                    font-weight: bold;
                    font-size: 62px;
                }
            }
            .imgContent{
                width: 75%;
                margin: auto;
                @media (min-width: 1440px){
                    width: 65%;
                }
                img{
                    position: relative;
                    width: 90%;
                    aspect-ratio: 1;
                    
                }
            }
        }
        .content_right{
            width: 55%;
            height: 100%;
            padding-top: 30px;

            .edSlide{
                padding: 55px 0 60px 40px;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
                max-height: 55%;
                .imgSlide{
                    padding: 0 4px;
                    height: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .content{
                height: calc(100% - 60%);
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 60px 0 40px;
                position: relative;
                p{
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 24px;
                }
                a{
                    font-size: 16px;
                    font-weight: bold;
                    color: #000;
                    padding: 12px 20px;
                    border-top: 1px solid #000;
                    border-bottom: 1px solid #000;
                    width: fit-content;
                    text-transform: uppercase;
                    transition: all .5s ease;
                    text-decoration: none;
                    &:hover{
                        padding: 12px 30px;
                    }
                }
                >img{
                    position: absolute;
                    bottom: 60px;
                    right: 60px;
                }
            }
        }
        @media (max-width: 992px){
            flex-direction: column;
            // height: calc(100vh - 110px);
            height: auto;
            border-bottom: 0;
            margin-bottom: 100px;
            .content_left{
                border-right: 0;
                width: 100%;
                border-top: 0.5px solid rgba(0, 0, 0, 0.5);
                padding-top: 50px;
                margin-top: 50px;
                img.icon{
                    display: none;
                }
                .title{
                    margin-top: 0;
                    text-align: left;
                    padding: 20px 0 10px;
                    padding-left: 20px;
                    border-bottom: 0;
                    margin-bottom: 50px;
                    h2{
                        font-size: 48px;
                        margin: 0;
                    }
                }
                .imgContent{
                    width: 100%;
                    padding: 0 20px;
                    img{
                        transform: unset !important;
                        width: 100%;
                        margin: auto;
                    }
                }

            }
            .content_right{
                padding-top: 0;
                width: 100%;
                .edSlide{
                    // display: none;
                    padding: 50px 20px;
                }
                .content{
                    height: 100%;
                    padding: 0 20px;
                    justify-content: flex-start;
                    >img{
                        display: none;
                    }
                }
            }
        }
    }
    
</style>