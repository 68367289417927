<template>
  <div class="section_7">
    <div class="content_left">
      <div class="title">
        <h2>Distribution Plan</h2>
      </div>
      <div class="chartPie">
        <apexchart
          @mouseMove="mouseMoveChart"
          width="550"
          type="donut"
          :options="options"
          :series="series"
        ></apexchart>
        <div class="total">
          <h2>Total NFTs</h2>
          <p>3000</p>
        </div>
      </div>
    </div>
    <div class="content_right">
      <ul>
        <li :class="flg1 ? 'show' : ''">
          <img src="../assets/wr_1.png" alt="" /> The Pros <span>20%</span>
        </li>
        <li :class="flg2 ? 'show' : ''">
          <img src="../assets/wr_2.png" alt="" /> The Gangs <span>20%</span>
        </li>
        <li :class="flg3 ? 'show' : ''">
          <img src="../assets/wr_3.png" alt="" /> The Challengers
          <span>20%</span>
        </li>
        <li :class="flg4 ? 'show' : ''">
          <img src="../assets/wr_4.png" alt="" /> The Manipulators
          <span>20%</span>
        </li>
        <li :class="flg5 ? 'show' : ''">
          <img src="../assets/wr_5.png" alt="" /> The Interns <span>20%</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flg1: false,
      flg2: false,
      flg3: false,
      flg4: false,
      flg5: false,
      options: {
        labels: [
          "The Pros",
          "The Gangs",
          "The Challengers",
          "The Manipulators",
          "The Interns",
        ],
        cssClass: "custom-1",
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                width: 300,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      },
      series: [500, 500, 500, 500, 500],
    };
  },
  methods: {
    mouseMoveChart(event, chartContext, config) {
      const ind = event.target.parentElement.getAttribute("data:realIndex");
      if (ind == 0) {
        this.flg1 = true;
        this.flg2 = false;
        this.flg3 = false;
        this.flg4 = false;
        this.flg5 = false;
      } else if (ind == 1) {
        this.flg1 = false;
        this.flg2 = true;
        this.flg3 = false;
        this.flg4 = false;
        this.flg5 = false;
      } else if (ind == 2) {
        this.flg1 = false;
        this.flg2 = false;
        this.flg3 = true;
        this.flg4 = false;
        this.flg5 = false;
      } else if (ind == 3) {
        this.flg1 = false;
        this.flg2 = false;
        this.flg3 = false;
        this.flg4 = true;
        this.flg5 = false;
      } else if (ind == 4) {
        this.flg1 = false;
        this.flg2 = false;
        this.flg3 = false;
        this.flg4 = false;
        this.flg5 = true;
      } else {
        this.flg1 = false;
        this.flg2 = false;
        this.flg3 = false;
        this.flg4 = false;
        this.flg5 = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section_7 {
  height: calc(100vh - 87px);
  display: flex;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);

  .content_left {
    width: 60%;
    border-right: 0.5px solid rgba(0, 0, 0, 0.5);

    .title {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
      margin-top: 40px;
      padding-left: 60px;
      h2 {
        margin: 10px 0 30px;
        font-family: "Neboma Sporte";
        font-weight: bold;
        font-size: 62px;
      }
    }
    .chartPie {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 168px);
      position: relative;
      .total {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h2 {
          font-size: 24px;
          font-weight: 700;
          color: #000;
          margin-bottom: 10px;
        }
        p {
          font-size: 56px;
          font-weight: 700;
          color: #000;
          font-family: "Neboma Sporte";
        }
        @media (max-width: 992px) {
          top: 54%;
          h2 {
            font-size: 16px;
            margin-bottom: 0;
          }
          p {
            font-size: 22px;
          }
        }
      }
      .apexcharts-canvas {
        margin: auto;
      }
    }
  }
  .content_right {
    width: 40%;
    ul {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      padding: 0 70px;
      height: 100%;
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        width: 100%;
        padding: 25px 0;
        position: relative;
        color: #000;
        transition: all 0.5s ease;
        span {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        > img {
          filter: grayscale(100%);
          transition: all 0.5s ease;
          transform: scale(1);
        }
        &:not(:last-child) {
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
        }
        &:nth-child(1) {
          &:hover,
          &.show {
            color: rgb(0, 143, 251);
            img {
              filter: grayscale(0%);
              transform: scale(1.2);
            }
          }
        }
        &:nth-child(2) {
          &:hover,
          &.show {
            color: rgb(0, 227, 150);
            img {
              filter: grayscale(0%);
              transform: scale(1.2);
            }
          }
        }
        &:nth-child(3) {
          &:hover,
          &.show {
            color: rgb(254, 176, 25);
            img {
              filter: grayscale(0%);
              transform: scale(1.2);
            }
          }
        }
        &:nth-child(4) {
          &:hover,
          &.show {
            color: rgb(255, 69, 96);
            img {
              filter: grayscale(0%);
              transform: scale(1.2);
            }
          }
        }
        &:nth-child(5) {
          &:hover,
          &.show {
            color: rgb(119, 93, 208);
            img {
              filter: grayscale(0%);
              transform: scale(1.2);
            }
          }
        }
        img {
          width: 50px;
          aspect-ratio: 1;
          border-radius: 50%;
        }
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 100px;
    .content_left {
      width: 100%;
      border-right: 0;
      .title {
        margin-top: 0;
        text-align: left;
        padding: 20px 0 10px;
        padding-left: 20px;
        margin-bottom: 30px;
        padding-top: 50px;
        border-bottom: 0;
        h2 {
          font-size: 48px;
          margin: 0;
        }
      }
      .chartPie {
        height: auto;
      }
    }
    .content_right {
      width: 100%;
      ul {
        width: 100%;
        padding: 0 0px;
        li {
          padding: 5px 20px;
          font-size: 16px;
          line-height: 24px;
          span {
            right: 20px;
          }
        }
      }
    }
  }
}
</style>