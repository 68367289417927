<template>
  <div class="header_normal">
    <header :class="{ fixMenu: isScrolled }">
      <div class="sumLogo">
        <a href="/" class="logo"><img src="../assets/LOGO.svg" alt="" /></a>
        <a class="cta" data-toggle="modal" data-target="#modalRef">
          <span class="span">Referral</span>
          <span class="second">
            <svg width="50px" height="20px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path class="one"
                  d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                  fill="#FFFFFF"></path>
                <path class="two"
                  d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                  fill="#FFFFFF"></path>
                <path class="three"
                  d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                  fill="#FFFFFF"></path>
              </g>
            </svg>
          </span>
        </a>
        <div class="socialPc">
          <a target="_blank" href="https://t.me/signalscryptoglobal"><img src="../assets/telegram.png" alt="" /></a>
          <a target="_blank" href="https://discord.gg/zodiac-arena"><img src="../assets/discord.png" alt="" /></a>
          <a target="_blank" href="https://twitter.com/Zodiac_Arena"><img src="../assets/twitter.png" alt="" /></a>

        </div>
      </div>
      <div class="iconMenu">
        <div id="nav-icon" :class="flgMenu ? 'open' : ''" @click="openMenu">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <nav :class="flgMenu ? 'open' : ''">
        <ul>

          <li :class="{ active: activeTab === 'roadmap' }">
            <a href="#roadmap" @click="navigateTo('roadmap')">Roadmap</a>
          </li>
          <li :class="{ active: activeTab === 'utility' }">
            <a href="#utility" @click="navigateTo('utility')">NFT Utilities</a>
          </li>

          <li :class="{ active: activeTab === 'tokenomic' }">
            <a href="#tokenomic" @click="navigateTo('tokenomic')">Distribution Plan</a>
          </li>
          <li :class="{ active: activeTab === 'storyline' }">
            <a href="#storyline" @click="navigateTo('storyline')">Storyline</a>
          </li>
          <li>
            <a href="https://token.zyncas.com" target="_blank">ZCS Token</a>
          </li>
          <li :class="{ active: activeTab === 'team' }">
            <a href="#team" @click="navigateTo('team')">About team</a>
          </li>
          <!-- <li>
            <a href="#" v-if="!twitterUser" @click="loginTwitter">Login with twitter</a>
            <a href="#" v-else @click="logoutTwitter">{{ twitterUser.displayName }}</a>
          </li> -->
          <!-- <li><a >Whitepaper</a></li> -->
          <!-- <li>
            <router-link to="/buy-nft" class="cta">
              <span class="span">Buy NFT</span>
              <span class="second">
                <svg width="50px" height="20px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path class="one"
                      d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                      fill="#FFFFFF"></path>
                    <path class="two"
                      d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                      fill="#FFFFFF"></path>
                    <path class="three"
                      d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                      fill="#FFFFFF"></path>
                  </g>
                </svg>
              </span>
            </router-link>
          </li> -->
          <!-- <li>
            <ConnectWallet />
          </li> -->
        </ul>
        <div class="social_sp">
          <a target="_blank" href="https://t.me/signalscryptoglobal"><img src="../assets/telegram.png" alt="" /></a>
          <a target="_blank" href="https://discord.gg/zodiac-arena"><img src="../assets/discord.png" alt="" /></a>
          <a target="_blank" href="https://twitter.com/Zodiac_Arena"><img src="../assets/twitter.png" alt="" /></a>
        </div>
      </nav>
    </header>
    <ModalReferral />

  </div>
</template>

<script>
import ModalReferral from "@/components/ModalReferral.vue";

import { getAuth, signInWithPopup, TwitterAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
const provider = new TwitterAuthProvider();

export default {
  components: {
    ModalReferral,
    // ConnectWallet
  },
  props: ["flgMenu"],
  data() {
    return {
      activeTab: "",
      isScrolled: false,
      auth: null,
      twitterUser: null
    };
  },
  methods: {
    openMenu() {
      this.flgMenu = !this.flgMenu;
    },
    linkToMenu(section) {
      this.flgMenu = false;
      this.moveToMenu(section);
    },
    navigateTo(tab) {
      this.activeTab = tab;
    },
    handleScroll() {
      const scrollPosition = window.scrollY;
      this.isScrolled = scrollPosition > 15;

      const sections = ["storyline", "utility", "roadmap", "tokenomic", "team"];
      const windowHeight = window.innerHeight;
      for (const section of sections) {
        const element = document.getElementById(section);

        if (element) {
          const { top, bottom } = element.getBoundingClientRect();
          if (top <= windowHeight / 2 && bottom >= windowHeight / 2) {
            this.activeTab = section;
            break;
          }
        }
      }
    },
    loginTwitter() {
      signInWithPopup(this.auth, provider)
        .then((result) => {
          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          const credential = TwitterAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // const secret = credential.secret;

          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          this.twitterUser = user
          console.log('access token', user.accessToken)
        }).catch((error) => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = TwitterAuthProvider.credentialFromError(error);
          // ...
        });
    },
    logoutTwitter() {
      signOut(this.auth).then(() => {
        this.twitterUser = null
      }).catch((error) => {
        // An error happened.
      });
    }
  },
  created() {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        this.twitterUser = user
        console.log('access token', user.accessToken)
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header_normal {
  transition: all 0.5s ease;
}

header {
  position: fixed;
  top: 15px;
  width: 100%;
  padding: 15px 60px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999;
  transition: all 0.5s ease;

  &.fixMenu {
    background: rgba(0, 0, 0, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.8px);
    border: 1px solid rgba(0, 0, 0, 0.08);
    top: 0;
    padding: 5px 20px;
  }

  .iconMenu {
    display: none;

    #nav-icon {
      width: 50px;
      height: 45px;
      position: relative;
      margin: 0px auto;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 50%;
        background: #fff;
        opacity: 1;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(even) {
          left: 50%;
          border-radius: 0 9px 9px 0;
        }

        &:nth-child(odd) {
          left: 1px;
          border-radius: 9px 0 0 9px;
        }

        &:nth-child(1),
        &:nth-child(2) {
          top: 0px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          top: 14px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          top: 28px;
        }
      }

      &.open {
        z-index: 9991;

        span {
          background: #000;

          &:nth-child(1),
          &:nth-child(6) {
            transform: rotate(45deg);
          }

          &:nth-child(2),
          &:nth-child(5) {
            transform: rotate(-45deg);
          }

          &:nth-child(1) {
            top: 10px;
            left: 5px;
          }

          &:nth-child(2) {
            left: calc(50% - 5px);
            top: 10px;
          }

          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }

          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }

          &:nth-child(5) {
            left: 5px;
            top: 25px;
          }

          &:nth-child(6) {
            left: calc(50% - 5px);
            top: 25px;
          }
        }
      }
    }
  }

  .sumLogo {
    display: flex;
    align-items: center;

    >a:not(:first-child) {
      text-transform: uppercase;
      text-decoration: none;
      transition: all 0.5s ease;
      color: #fff;
      font-weight: 500;
      margin-left: 20px;
      padding-left: 20px;
      // background: #fbc638;
      cursor: pointer;

      // border-left: 1px solid #fff;
      // box-shadow: 10px 10px 0 #6225e6;
      &:hover {
        // box-shadow: 10px 10px 0 #fff;
      }

      @media (max-width: 992px) {
        padding-left: 10px;

        .second {
          margin-left: 10px;
        }

        &:hover {
          .second {
            margin-right: 15px;
          }
        }
      }
    }

    .socialPc {
      display: flex;
      gap: 20px;
      margin-left: 20px;

      a {
        background: #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          img {
            width: 24px;
            height: 24px;
          }
        }

        img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  nav {
    ul {
      display: flex;
      gap: 10px;
      align-items: center;

      li {
        a {
          font-size: 14px;
          font-weight: 500;
          padding: 8px 12px;
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          transition: all 0.5s ease;
          border-top: 1px solid transparent;
          border-bottom: 1px solid transparent;
          font-weight: bold;
          cursor: pointer;

          @media (min-width: 993px) {
            &:hover {
              border-top: 1px solid #fff;
              border-bottom: 1px solid #fff;
              color: #fff;
            }
          }
        }

        &:last-child {
          a {
            color: #fff;
            font-weight: bold;
            border: transparent;

            @media (min-width: 993px) {
              &:hover {
                // background: #000;
                // color: #fff;
              }
            }
          }
        }

        &.active {
          a {
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
          }
        }
      }
    }

    .social_sp {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    padding: 16px 20px;
    border-top: 0;
    top: 0;

    .socialPc {
      display: none !important;
    }

    .iconMenu {
      display: inline-block;
    }

    nav {
      position: fixed;
      width: 100%;
      right: -100%;
      height: 100vh;
      top: 0;
      z-index: 999;
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(6px);
      transition: all 0.5s ease;
      opacity: 0;
      visibility: hidden;

      ul {
        flex-direction: column;
        width: 70%;
        background: #fff;
        height: 100%;
        margin-left: auto;
        justify-content: center;
        align-items: end;
        padding-right: 40px;

        li {
          a {
            color: #000;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 20px;
            display: block;
          }
        }
      }

      .social_sp {
        display: flex;
        position: absolute;
        width: fit-content;
        bottom: 120px;
        display: flex;
        justify-content: space-between;
        right: 60px;
        gap: 20px;
      }

      &.open {
        right: 0%;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.dark {
  height: 86px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;

  header {
    border-color: #808080;

    .iconMenu {
      #nav-icon {
        span {
          background: #000;
        }
      }
    }

    .sumLogo {
      >a {
        &:last-child {
          color: #000;
          border-left: 1px solid #000;
        }
      }
    }

    nav {
      ul {
        li {
          a {
            color: #000;

            @media (min-width: 993px) {
              &:hover {
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                color: #000;
              }
            }
          }

          &:last-child {
            a {
              color: #fff;
              background: #000;
              border: 1px solid #000;

              @media (min-width: 993px) {
                &:hover {
                  background: #fff;
                  color: #000;
                }
              }
            }
          }

          &.active {
            a {
              border-top: 1px solid #000;
              border-bottom: 1px solid #000;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    height: 79px;
  }
}
</style>
