<template>
    <div class="layout-buynft">
        <FlashMessage :position="'right bottom'" />
        <div class="slide_12_zodiac">
            <slick v-if="animals" class="sliderFor" :options="slickOptionsZodiac" @afterChange="changeItem">
                <div class="imgSlide" v-for="(animal, index) in animals" :key="index"
                    :class="{ 'active-slide': activeIndex === index }">
                    <img :src="animal.img" alt="">
                    <h2>{{ animal.name }}</h2>
                </div>
            </slick>

            <div v-if="animals" class="animal-list">
                <h2>Possibility</h2>
                <div class="animal-item" v-for="(animal, index) in animals" :key="index">
                    <div class="animal-images" v-if="activeIndex === index">
                        <img v-for="(picture, picIndex) in animal.pictures" :key="picIndex" :src="picture" />
                    </div>
                </div>
            </div>
            <button @click="startRolling" data-toggle="modal" data-target="#modalRoll" class="btnRoll">Start to
                Roll</button>
            <ModalRollNftVue v-if="currentZodiac" :show-modal="showModal" :random-picture="randomPicture"
                :current-zodiac="currentZodiac" :nft="nft" @re-roll="reRoll" @buy-now="buyNow"
                @imageLoaded="handleImageLoaded" />


        </div>
        <Loading :start="!!loading" />
    </div>
</template>

<script>
import Slick from 'vue-slick';
import ModalRollNftVue from './ModalRollNft.vue';
import Loading from "@/components/Loading.vue";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, getDocs, orderBy, collection, query } from "firebase/firestore";
import Web3 from 'web3'
import sha3 from 'js-sha3'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDEE1USfyjqze0W2dSZOntxUjp-0rvFYhE",
    authDomain: "signals-61284.firebaseapp.com",
    databaseURL: "https://signals-61284.firebaseio.com",
    projectId: "signals-61284",
    storageBucket: "signals-61284.appspot.com",
    messagingSenderId: "727577331880",
    appId: "1:727577331880:web:bb39906b254568a8d8da9d",
    measurementId: "G-Y4LSNQCLQF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
let db
if (process.env.NODE_ENV == 'development') {
    db = getFirestore(app, "development");
} else {
    db = getFirestore(app);
}

import { init, useOnboard } from '@web3-onboard/vue'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseWalletModule from '@web3-onboard/coinbase'
import { ethers } from 'ethers'
import abi from './abi.json'

const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const injected = injectedModule()
const chain = process.env.NODE_ENV == 'production' ?
    {
        id: 137,
        token: 'MATIC',
        label: 'Polygon',
        rpcUrl: 'https://polygon-rpc.com'
    } :
    {
        id: 80002,
        token: 'MATIC',
        label: 'POLYGON AMOY TESTNET',
        rpcUrl: 'https://rpc-amoy.polygon.technology/'
    }


init({
    wallets: [coinbaseWalletSdk, injected],
    connect: { autoConnectLastWallet: true },
    chains: [chain]
})

const { connectWallet, connectedChain, setChain, connectedWallet } = useOnboard()

export default {
    components: {
        Slick,
        ModalRollNftVue,
        Loading
    },
    data() {
        return {
            loading: false,
            activeIndex: 0,
            showModal: false,
            randomPicture: '',
            currentZodiac: null,
            slickOptionsZodiac: {
                slidesToShow: 5,
                swipe: false,
                centerMode: true,
                centerPadding: '0',
                dots: false,
                autoplay: false,
                infinite: false,
                // speed: 1000,
                prevArrow: false,
                nextArrow: false,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 552,
                        settings: {
                            slidesToShow: 3,
                        },
                    },

                ]
            },
            animals: null,
            nft: null,
        }
    },
    methods: {
        changeItem(currentSlide) {
            const self = this;
            self.activeIndex = currentSlide.currentTarget.slick.currentSlide;
            this.currentZodiac = this.animals[this.activeIndex];
        },
        async startRolling() {
            this.showModal = true;
            this.currentZodiac = this.animals[this.activeIndex];
            await this.reRoll()
        },
        async reRoll() {
            this.loading = true;

            const body = {
                "data": {
                    "head": this.currentZodiac.headType[Math.floor(Math.random() * this.currentZodiac.headType.length)]
                }
            };

            console.log('Rolling...')
            const response = await fetch('https://us-central1-signals-61284.cloudfunctions.net/generateCodeNFT', {
                method: 'post',
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            const res = data.result
            console.log('Finish', res)

            if (res.status != 'success') {
                this.flashMessage.error({
                    title: 'Rolling NFT failed',
                    message: ''
                });
                return
            }

            this.nft = res.data

            const hash = (string) => {
                const uintValue = Web3.utils
                    .toBigInt("0x" + sha3.keccak256(string).toString("hex"))
                    .toString();
                return uintValue.substring(0, 10);
            };

            const hashCode = hash(`${this.nft.bg}${this.nft.back}${this.nft.body}${this.nft.head}`);

            this.randomPicture = `https://firebasestorage.googleapis.com/v0/b/signals-61284.appspot.com/o/zyncas_NFT_images%2F${this.nft.head}%2F720x720%2Fzyncas_NFT_${hashCode}_720x720.png?alt=media`
        },
        handleImageLoaded() {
            this.loading = false;
        },
        async buyNow() {
            const body = {
                "data": {
                    "classType": this.nft.classType,
                    "back": this.nft.back,
                    "body": this.nft.body,
                    "head": this.nft.head,
                    "bg": this.nft.bg
                }
            };

            console.log('Verifying...')
            const response = await fetch('https://us-central1-signals-61284.cloudfunctions.net/checkValidNFT', {
                method: 'post',
                body: JSON.stringify(body),
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            const res = data.result

            if (res.status != 'success') {
                this.flashMessage.error({
                    title: 'Invalid NFT',
                    message: ''
                });

                return
            }

            if (!connectedWallet.value) {
                await connectWallet()
            }

            if (connectedChain?.value.id != chain.id) {
                await setChain({ wallet: connectedWallet.value.label, chainId: chain.id })
            }

            const provider = new ethers.BrowserProvider(connectedWallet.value.provider, 'any')
            const signer = await provider.getSigner()
            const contract = new ethers.Contract('0x60b0334fa0d05a6c69a0752b626ea8d0baf3f617', abi, signer)

            try {
                const options = { value: ethers.parseEther("0.001") }
                console.log('Call buyNFT', this.nft.classType, this.nft.back, this.nft.body, this.nft.head, this.nft.bg, signer.address, '')
                const tx = await contract.buyNFT(this.nft.classType, this.nft.back, this.nft.body, this.nft.head, this.nft.bg, signer.address, '', options)
                console.log('tx hash', tx.hash)
                await tx.wait()
                this.flashMessage.success({
                    title: 'Success',
                    message: 'Congratulations! You got a new awesome NFT! Check it out in your collection now!'
                });
            } catch (error) {
                console.log('tx error', error)
                this.flashMessage.error({
                    title: 'Transaction reverted',
                    message: error.reason
                });
            }
        },
    },
    async mounted() {
        const q = query(collection(db, "zodiacs"), orderBy("order"));
        const querySnapshot = await getDocs(q);
        let animals = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            animals.push({
                name: data.name,
                pictures: data.possibilities,
                img: data.possibilities[0],
                headType: data.headType
            })
        });
        this.animals = animals
        this.currentZodiac = this.animals[this.activeIndex];
    },
    computed: {

    },
}
</script>

<style lang="scss" scoped>
.layout-buynft {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;

    .slide_12_zodiac {
        .imgSlide {
            padding: 10px;
            cursor: pointer;
            opacity: .4;
            filter: blur(2px);
            transition: all .5s ease;
            border-bottom: 1px solid #ccc;

            &:hover {
                opacity: 1;
                filter: blur(0px);

            }

            img {
                width: 100%;
                border-radius: 10px;

            }

            h2 {
                color: #fff;
                font-size: 18px;
                margin-top: 15px;
                text-align: center;
            }
        }

        .slick-current {
            .imgSlide {
                opacity: 1;
                filter: blur(0px);
            }
        }

        .animal-list {
            width: 80%;
            margin: auto;

            @media (max-width: 552px) {
                width: 100%;
            }

            >h2 {
                color: #fff;
                font-size: 16px;
                text-align: center;
                margin: 50px 0;
            }

            .animal-item {
                .animal-images {
                    display: flex;
                    gap: 20px;
                    flex-wrap: wrap;

                    @media (max-width: 552px) {
                        gap: 10px;
                    }

                    img {
                        width: calc((100% / 5) - 16px);

                        @media (max-width: 992px) {
                            width: calc((100% / 4) - 16px);
                        }

                        @media (max-width: 552px) {
                            width: calc((100% / 3) - 7px);
                        }

                        // width: 100%;
                        border-radius: 10px;
                    }
                }
            }
        }

        .btnRoll {
            position: fixed;
            bottom: 20px;
            min-width: 250px;
            color: #fff;
            background: black;
            border-radius: 50px;
            border: 0;
            font-size: 14px;
            padding: 10px 0;
            left: 50%;
            translate: -50% 0;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            transition: all .5s ease;

            &:hover {
                background: #fff;
                color: #000;
            }

            @media (max-width: 552px) {
                min-width: unset;
                left: 15px;
                right: 15px;
                translate: unset;
            }
        }
    }
}
</style>