<template>
    <div class="section_5">
        <div class="roadmapPage">
            <div class="title">
                <h2>Roadmap</h2>
            </div>
            <div class="roadmap">
                <div class="rm_top">
                    <div class="item">
                        <img src="../assets/rm_1.png" alt="">
                    </div>
                    <div class="item">
                        <h2>Q3/2024</h2>
                        <!-- <p><a target="_blank" href="http://zperp.io/">zperp.io</a> DEX Testnet</p> -->
                        <!-- <h5>08/2023</h5> -->
                        <p>- Partnership</p>
                        <p>- Zodiac NFT sale</p>
                        <p>- Access to Crypto Trading App by Zyncas</p>
                        <p>- Exclusive 6 months Access to Auto Trading Bot by Zyncas</p>
                        <p>- ZBot Trading</p>


                    </div>
                    <div class="item">
                        <img src="../assets/rm_3.png" alt="">
                    </div>
                    <div class="item">
                        <h2>Q1/2025 & Beyond</h2>
                        <!-- <p>ZCS Token Release</p> -->
                        <!-- <h5>12/2023</h5> -->
                        <p>- Future Airdrops From Partners</p>
                        <p>- More CEXes Listing For $ZCS</p>
                    </div>
                    <!-- <div class="item">
                        <img src="../assets/rm_5.png" alt="">
                    </div> -->
                </div>
                <div class="lineCenter">
                    <img class="line_rn_hori" src="../assets/line_rn.png" alt="">
                    <img class="line_rn_vert" src="../assets/line_rn_vert.png" alt="">
                    <div class="listItem">
                        <div class="item active">
                            <div class="square"></div>
                            <img src="../assets/lineSquare.png" alt="">
                        </div>
                        <div class="item">
                            <img src="../assets/lineSquare.png" alt="">
                            <div class="square"></div>
                        </div>
                        <div class="item">
                            <div class="square"></div>
                            <img src="../assets/lineSquare.png" alt="">
                        </div>
                        <div class="item">
                            <img src="../assets/lineSquare.png" alt="">
                            <div class="square"></div>
                        </div>
                        <!-- <div class="item">
                            <div class="square"></div>
                            <img src="../assets/lineSquare.png" alt="">
                        </div> -->
                    </div>
                </div>
                <div class="rm_top">
                    <div class="item">
                        <h2>Q2/2024</h2>
                        <!-- <p>NFT Public sales</p> -->
                        <!-- <h5>06/2023</h5> -->
                        <p>- Genesis NFT Sale</p>
                        <p>- Available For Lifetime Premium Access To Crypto Trading App By Zyncas</p>
                        <p>- Exclusive Access To <a href="#">Zperp.io</a> Testnet</p>
                        <p>- ZBot Trading</p>

                        <p>-Signal App improvement</p>

                        <!-- <p>- NFT Public Sale</p> -->
                        <!-- <p>- OpenSea Listing</p> -->
                        <!-- <p>- Available For Lifetime Premium Access To Crypto Trading App By Zyncas</p>
                        <p>- Exclusive Access To Zperp.io Testnet</p>
                        <p>- ZBot Trading</p> -->
                    </div>
                    <div class="item">
                        <img src="../assets/rm_2.png" alt="">
                    </div>
                    <div class="item">
                        <h2>Q4/2024</h2>
                        <!-- <p><a target="_blank" href="http://zperp.io/">zperp.io</a> DEX Mainnet</p> -->
                        <!-- <h5>10/2023</h5> -->
                        <p>- $ZCS Airdrop For NFT Holders</p>
                        <p>- NFT DAO Governance</p>
                        <p>- NFT Staking</p>
                        <p>- NFT Lending</p>
                        <p>- Zodiac Arena Launchpad</p>
                        <p>- $ZCS CEX Listing</p>
                        <p>- Exclusive Major Partnerships</p>
                    </div>
                    <div class="item">
                        <img src="../assets/rm_4.png" alt="">
                    </div>
                    <!-- <div class="item">
                        <h2>8/2024</h2>
                        <p>NFT DAO & Staking/ Lending</p>
                        <h5>02/2024</h5>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {

        },        
    }
</script>

<style lang="scss" scoped>
    .roadmapPage{
        padding: 0px 0px 0;
        @media (min-width: 993px){
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
        .title{
            padding: 55px 60px;
            // border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
            h2{
                font-size: 62px;
                font-family: 'Neboma Sporte';
                font-weight: bold;
                margin: 0;
            }
            @media (min-height: 700px){
                padding: 35px 60px;
            }
        }
        .lineCenter{
            position: relative;
            margin: 20px 0 10px;
            >img{
                position: absolute;
                left: 0;
                z-index: -1;
                top: 15px;
                width: 100%;
                &.line_rn_vert{
                    display: none;
                }
            }
            .listItem{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                .item{
                    text-align: center;
                    width: 100%;
                    position: relative;
                    @media (max-width: 992px){
                        min-height: 210px;
                    }
                    .square{
                        width: 32px;
                        transform: rotate(45deg);
                        margin: auto;
                        aspect-ratio: 1;
                        background: #fff;
                        border: 4px solid #000;
                        transition: all .5s ease;
                    }
                    >img{
                        z-index: -1;
                    }
                    &:nth-child(odd){
                        >img{
                            position: absolute;
                            bottom: -60px;
                        }
                    }
                    &:nth-child(even){
                        >img{
                            transform: rotate(180deg);
                            position: absolute;
                            top: -60px;
                        }
                    }
                    &.active, &:hover{
                        .square{
                            border: 12px solid #000;
                        }
                    }
                }
            }
        }
        .roadmap{
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 30px;
            padding-bottom: 150px;
            margin-top: 50px;
            border-bottom: 1px solid #000;
            @media (max-width: 992px){
                padding-bottom: 50px;
                border-bottom: 0;
            }
            .rm_top{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                .item{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    img{
                        width: 200px;
                    }
                    h2{
                        font-family: 'Neboma Sporte';
                        font-size: 40px;
                        line-height: 56px;
                        color: #000;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    h5{
                        font-size: 14px;
                    }
                }
            }
        }
        @media (max-width: 992px){
            padding-top: 50px;
            .lineCenter{
                // display: none;
                img{
                    &.line_rn_hori{
                        display: none;
                    }
                    &.line_rn_vert{
                        display: block;
                        width: auto;
                        height: 100%;
                        left: 10px;
                    }
                }
                .listItem{
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-around;
                    .item{
                        .square{
                            width: 21px;
                            border: 2px solid #000;
                        }
                        >img{
                            height: 55px;
                        }
                        &:nth-child(odd){
                            >img{
                                top: -17px;
                                transform: rotate(-90deg);
                                right: -100%;

                            }
                        }
                        &:nth-child(even){
                            >img{
                                transform: rotate(90deg);
                                top: -17px;
                                left: -100%;
                            }
                        }
                        &.active, &:hover{
                            .square{
                                border: 8px solid #000;
                            }
                        }
                    }
                }
            }
            .title{
                width: 100%;
                padding: 0;
                padding-left: 20px;
                padding-top: 10px;
                // display: none;
                h2{
                    font-size: 48px;
                }
            }
            .roadmap{
                flex-direction: row;
                height: auto;
                gap: 30px;
                margin-top: 50px;
                margin-bottom: 50px;
                .rm_top{
                    flex-direction: column;
                    justify-content: space-evenly;
                    width: 50%;
                    .item{
                        height: 100%;
                        img{
                            width: 130px;
                        }
                        h2{
                            font-size: 26px;
                            line-height: 37px;
                            margin-bottom: 0;
                        }
                        p{
                            font-size: 12px;
                        }
                        h5{
                            font-size: 10px;

                        }
                    }
                }
            }
        }
    }
</style>