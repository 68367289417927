<template>
    <main class="miniGame_view">
        <!-- <div class="back-home">
            <router-link to="/"><img src="../assets/LOGO.svg" alt="" /></router-link>
            <router-link to="/">Home</router-link>
        </div> -->
        <LuckyWheel />
    </main>
</template>

<script>
import LuckyWheel from "@/components/MiniGame/LuckyWheel.vue";
export default {
    name: "MiniGameView",
    components: {
        LuckyWheel
    },
}
</script>

<style lang="scss" scoped>
.miniGame_view {
    min-height: 100vh;
    background: url(../assets/bg_1.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;
}
</style>
