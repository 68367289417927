<template>
  <div class="howtobuy-page">
    <div class="back-home">
      <router-link to="/"><img src="../assets/LOGO.svg" alt="" /></router-link>
      <router-link to="/">Home</router-link>
    </div>
    <div class="banner">
      <h1>How To Buy NFT Guidelines</h1>
    </div>
    <div class="content-howtobuy">
      <div class="step">
        <h2>Prerequisites</h2>
        <ul class="step-ul">
          <h3>
            Now we currently support 3 options to buy Zodiac NFTs in our first
            launchpad project:
          </h3>
          <li>Metamask / Coinbase Wallet</li>
          <li>In-app-purchase (Credit / Debit Card)</li>
          <p>iOS App Store</p>
          <p>Google Play Store</p>
          <li>Via Telegram payment</li>
        </ul>
      </div>

      <div class="step">
        <h2>How to buy our Zodiac NFT</h2>
        <ul class="step-ul">
          <h3>Step 1: Discover Zodiac Arena on the first mobile Launchpad</h3>
          <li>
            Download <b>Signals - Crypto:</b>
            <a target="_blank" href="https://app.signals.zyncas.com/w5Eb"
              ><i>Link</i></a
            >
          </li>
          <li>Discover our launchpad program in <b>Launchpad</b> tab</li>
          <li>
            Read our inspired story about Zodiac Arena to understand what an NFT
            can bring to us.
          </li>
          <li>
            Tap <b>“Buy Now”</b> after having a deep understanding about the
            programme.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_1-1.png" alt="" />
            </div>
            <div class="item-img">
              <img src="../assets/step_1-2.png" alt="" />
            </div>
          </div>
        </ul>
        <ul class="step-ul">
          <h3>Step 2: Find your representing personality zodiac sign</h3>
          <li>
            Choose 1 in 13 uniquely designed zodiac animals in our collection
          </li>
          <li>
            Click "Start to Roll" to randomly get your zodiac animal with the
            best puzzle pieces
          </li>
          <li>
            In case of dissatisfaction, you can make re-rolling until you get
            your satisfied one.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_2-1.png" alt="" />
            </div>
            <div class="item-img">
              <img src="../assets/step_2-2.png" alt="" />
            </div>
          </div>
        </ul>
        <ul class="step-ul">
          <h3>Purchase your NFT</h3>
          <li>
            After finding the zodiac animal you love, proceed to make the
            purchase immediately to avoid losing it to other NFT hunters.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_3-1.png" alt="" />
            </div>
          </div>
          <h3>Using Metamask / Coinbase Wallet</h3>
          <li>
            Download one of these two best external wallets if you haven’t had
            any yet:
          </li>
          <p>
            Download Metamask:
            <a href="https://metamask.io/download/" target="_blank"
              ><i>Link</i></a
            >
          </p>
          <p>
            Download Coinbase Wallet:
            <a
              href="https://www.coinbase.com/wallet/downloads?__cf_chl_f_tk=x81zsdL6rEq1Ie7ulZTZnfhmbLcb7gNHK706Z1bzb80-1710581139-0.0.1.1-1663"
              target="_blank"
              ><i>Link</i></a
            >
          </p>
          <li>
            After tapping “Buy Now” button, choose Metamask or Coinbase Wallet
            payment method based on your current external wallet.
          </li>
          <div class="img-step">
            <div class="item-img">
              <p>
                You will be asked to connect to our application Metamask, tap
                “Connect”
              </p>
              <img src="../assets/step_3-2.png" alt="" />
              <span>Sign the connect message on Metamask</span>
            </div>
            <div class="item-img">
              <p>
                If you use Coinbase Wallet, tap “Allow” to connect to our
                application
              </p>
              <img src="../assets/step_3-3.png" alt="" />
              <span>Sign the connect message on Coinbase</span>
            </div>
          </div>
          <div class="img-step">
            <div class="item-img">
              <p>
                Tap “Confirm” on Metamask to make<br />
                purchase.
              </p>
              <img src="../assets/step_3-4.png" alt="" />
            </div>
            <div class="item-img">
              <p>Tap “Confirm” on Coinbase Wallet to make purchase.</p>
              <img src="../assets/step_3-5.png" alt="" />
            </div>
          </div>
          <li>
            When you see the “Minting NFT successfully!”, your purchase is done.
          </li>
          <h3 style="margin-top: 30px">Using In-App-Purchase</h3>
          <li>
            We also support payment methods for users who don’t want to make
            purchase via cryptocurrencies. You can use in-app-purchase built
            right-in the app to make purchase via Debit / Credit cards or Apple
            Pay / Google Pay.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_3-6.png" alt="" />
              <span>Making purchase on iOS</span>
            </div>
            <div class="item-img">
              <img src="../assets/step_3-7.png" alt="" />
              <span>Finish purchase on iOS</span>
            </div>
          </div>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_3-8.png" alt="" />
              <span>Making purchase on Android</span>
            </div>
            <div class="item-img">
              <img src="../assets/step_3-9.png" alt="" />
              <span>Finish purchase on Android</span>
            </div>
          </div>
          <h3>Using Telegram payment</h3>
          <li>
            This method is for users who don’t only have any cryptocurrencies’
            wallet and but also credit / debit cards.
          </li>
          <p>
            Tap to “Telegram” payment method on the app and your payment QR code
            will be saved to your Photos / Camera Roll.
          </p>
          <p>
            Send the code to our super admin
            <a href="https://t.me/tieushinshin" target="_blank"
              ><i>@tieushinshin</i></a
            >
            via Telegram.
          </p>
          <p>
            He will take responsibility to help you continue the payment
            process.
          </p>
          <p>
            After making payment, he will send your desired NFT directly to the
            app.
          </p>
        </ul>
        <ul class="step-ul">
          <h3>Step 4: Checking your purchased NFTs in “My NFTs” section</h3>
          <li>
            After making purchase, you will receive a notification message
            “Minting NFT successfully!”. That means your process is done.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_4-1.png" alt="" />
              <span>Done purchase process on iOS</span>
            </div>
            <div class="item-img">
              <img src="../assets/step_4-1-1.png" alt="" />
              <span>Done purchase process on Android</span>
            </div>
          </div>
          <li>
            Go back to Launchpad tab, check your purchased NFTs in “My NFTs”
            section.
          </li>
          <div class="img-step">
            <div class="item-img">
              <img src="../assets/step_4-2.png" alt="" />
            </div>
            <div class="item-img">
              <img src="../assets/step_4-3.png" alt="" />
            </div>
          </div>
          <li>
            If you see your NFTs there, then congratulations. You are one of the
            first who have Zodiac NFT in your collection. You can claim your
            NFTs after the launchpad finishes.
          </li>
        </ul>
      </div>

      <div class="step">
        <h2>Things to pay attention to</h2>
        <ul class="step-ul">
          <li>
            There is no limit quantity of NFTs to a users which means one users
            can buy as many NFTs as they want to.
          </li>
          <li>
            Re-rolling time is unlimited, you can re-roll many times as you can
            to find your desired NFT.
          </li>
          <li>
            If you make purchase via Metamask or Coinbase wallet, please make
            sure that you have enough ETH (include price and fee) in your wallet
            before sending transaction.
          </li>
          <li>
            After making purchase successfully, if you don’t see your NFT in “My
            NFTs” section please send direct message to our admin
            <a href="https://t.me/tieushinshin" target="_blank"
              ><i>@tieushinshin</i></a
            >
            via Telegram immediately.
          </li>
          <li>
            All of NFTs could be listed on secondary NFT market such as OpenSea,
            Rarity…
          </li>
        </ul>
      </div>

    </div>
    <!-- <Header /> -->
    <!-- <Loading :start="load.val" /> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";
import WOW from "wowjs";
// import Loading from "@/components/Loading.vue";
// import Totop from "@/components/Totop.vue";


export default {
  name: "HowToBuy",
  components: {
    // Header,
    // Body,
    // Footer,
    // Loading,
    // Totop,
    // ModalReferral
  },
  data() {
    return {
      load: {
        val: false,
      },
    };
  },
  provide() {
    return {
      myLoading: this.load,
    };
  },
  mounted() {
    let wow = new WOW.WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
  },
};
</script>

<style lang="scss" scope>
.howtobuy-page {
  min-height: 100vh;
  background: url(../assets/bg_1.jpg);
  background-size: cover;
  background-position: center;
  padding-bottom: 100px;
  .back-home {
    position: fixed;
    top: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.8px);
    border: 1px solid rgba(0, 0, 0, 0.08);
    top: 0;
    padding: 5px 20px;
    a {
      &:last-child {
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.5s ease;
        color: #fff;
        font-weight: 500;
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #fff;
      }
    }
  }
  .banner {
    padding-top: 100px;
    h1 {
      text-align: center;
      font-weight: bold;
      color: white;
      font-size: 72px;
    }
    @media (max-width: 992px) {
      padding-top: 100px;
      h1 {
        font-size: 38px;
      }
    }
  }
  .content-howtobuy {
    width: 80%;
    margin: auto;
    padding-top: 50px;
    color: white;
    @media (max-width: 992px) {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    img {
      width: 50%;
      margin: 15px auto;
      display: block;
      border-radius: 32px;
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    h2 {
      font-weight: bold;
      font-size: 40px;
    }
    h4.note {
      font-weight: bold;
      font-size: 18px;
      font-style: italic;
      margin-top: 20px;
    }
    .step {
      margin-bottom: 100px;
      p {
        padding-left: 40px;
        position: relative;

        &::after {
          content: "";
          width: 7px;
          height: 7px;
          background: transparent;
          border: 1px solid #fff;
          border-radius: 100%;
          left: 15px;
          top: 9px;
          position: absolute;
        }
      }
      ul.step-ul {
        padding-left: 50px;
        margin-top: 50px;
        h3 {
          font-weight: bold;
        }
        li {
          font-size: 16px;
          padding-left: 50px;
          margin-top: 5px;
          position: relative;
          &::after {
            content: "";
            width: 7px;
            height: 7px;
            background: #fff;
            border-radius: 100%;
            left: 20px;
            top: 9px;
            position: absolute;
          }
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
        > p {
          margin-left: 100px;
          padding-bottom: 0;
          font-size: 16px;
          a {
            color: #fff;
            text-decoration: underline;
          }
        }
        .img-step {
          display: flex;
          gap: 20px;
          margin: 20px 0;
          justify-content: center;
          .item-img {
            width: calc(100% / 3);
            p {
              padding-left: 0;
              &::after {
                display: none;
              }
            }
            span {
              font-size: 14px;
              font-style: italic;
              margin-top: -10px;
              display: block;
            }
            img {
              width: 100%;
            }
          }
          @media (max-width: 992px) {
            flex-direction: column;
            .item-img {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width: 992px) {
      h2 {
        font-size: 28px;
      }
      h4.note {
        font-size: 14px;
      }
      .step {
        margin-bottom: 50px;
        ul.step-ul {
          padding-left: 0px !important;
          margin-top: 0;
          h3 {
            font-size: 16px;
          }
          li {
            padding-left: 30px;
          }
          p {
            margin-left: 30px;
          }
        }
      }
    }
  }
}
</style>