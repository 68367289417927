<template>
    <div class="progress_nft">
        <h2>Minting</h2>
        <div class="progress_bar">
            <div class="current_bar" :style="progressStyle"/>
            <span>{{ phase2 }}/{{ total }}</span>
        </div>
    </div>
</template>

<script>
    // Import the functions you need from the SDKs you need
    import { initializeApp } from "firebase/app";
    import { getAnalytics } from "firebase/analytics";
    import { getFirestore, doc, onSnapshot, collection, query, where } from "firebase/firestore";


    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyDEE1USfyjqze0W2dSZOntxUjp-0rvFYhE",
        authDomain: "signals-61284.firebaseapp.com",
        databaseURL: "https://signals-61284.firebaseio.com",
        projectId: "signals-61284",
        storageBucket: "signals-61284.appspot.com",
        messagingSenderId: "727577331880",
        appId: "1:727577331880:web:bb39906b254568a8d8da9d",
        measurementId: "G-Y4LSNQCLQF"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);

    // Initialize Cloud Firestore and get a reference to the service
    let db
    if (process.env.NODE_ENV == 'development') {
        db = getFirestore(app, "development");
    } else {
        db = getFirestore(app);
    }

    console.log('Env', process.env.NODE_ENV)

    export default {
        components: {

        },
        data(){
            return{
                total: 100,
                current: 0,
                phase2: 100
            }
        },
        methods: {

        },
        mounted() {
            onSnapshot(collection(db, "launchpads"), (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data()
                    if (data.id == 1) {
                        return this.current = data.sold
                    }
                });
            });
        },
        computed: {
            progressStyle() {
                // const percentage = (this.current / this.total) * 100;
                const percentage = (this.phase2 / this.total) * 100;
                return `width: ${percentage}%;`;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .progress_nft{
        width: 50%;
        margin: auto;
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 992px){
            width: 100%;
            padding: 0 20px;
        }
        >h2{
            color: black;
            margin: 0;
            font-size: 18px;
        }
        .progress_bar{
            flex: 1;
            background: #ccc;
            position: relative;
            height: 20px;
            border-radius: 50px;
            // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            span{
                font-size: 12px;
                line-height: 20px;
                display: inline-block;
                z-index: 10;
                position: relative;
                font-weight: bold;
                color: #000;
            }
            .current_bar{
                position: absolute;
                transition: width 0.3s ease-in-out;
                z-index: 7;
                left: 0px;
                top: 0px;
                height: 20px;
                background: #00f043;
                border-radius: 50px;

            }
        }
    }
</style>