<template>
    <div class="modal fade" id="modalRef" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content edModalContent">
                <div class="modal-body modalRef">
                    <h2>Referral</h2>
                    <button class="close" data-dismiss="modal" aria-label="Close">
                        <img src="../assets/close.png" alt="">
                    </button>
                    <div class="formRef">
                        <input type="text" v-model="inputCode">
                        <button @click="searchCode" class="searchRef">Search</button>
                    </div>

                    <div class="responData" :class="getDataSuccess ? 'open' : ''">
                        <div class="tableData">
                            <h2>Balance</h2>
                            <p>{{balance}}$</p>
                        </div>
                        <div class="tableData">
                            <h2>Percentage</h2>
                            <p>{{percentage * 100}}%</p>
                        </div>
                        <div class="tableData">
                            <h2>Referrees</h2>
                            <p>{{referrees}}</p>
                        </div>
                    </div>
                    <h3 :class="errorGet ? 'show' : ''">Not found data</h3>
                </div>
                <Loading :start="!!loading" />
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from "@/components/Loading.vue";

    export default {
        components: { 
            Loading
        },
        data(){
            return{
                inputCode: '',
                loading: false,
                balance: 0,
                percentage: 0,
                referrees: 0,
                errorGet: false,
                getDataSuccess: false,

            }
        },
        methods: {
            async searchCode(){
                this.loading = true;
                const respon = await this.axios.get('https://us-central1-signals-61284.cloudfunctions.net/api/rewards?referral_code='+ this.inputCode);
                if(respon.data.data.balance){
                    this.balance = respon.data.data.balance;
                    this.percentage = respon.data.data.referrer_percentage;
                    this.referrees = respon.data.data.amount;
                    this.errorGet = false;
                    this.getDataSuccess = true;
                    this.loading = false;


                }
                else{
                    this.errorGet = true;
                    this.getDataSuccess = false;
                    this.loading = false;
                    return;
                }
            }
        },
        async mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .edModalContent{
        border-radius: 32px;
    }
    .modalRef{
        padding: 50px 50px 50px;
        @media (max-width: 992px){
            padding: 50px 20px 50px;

        }
        .close{
            position: absolute;
            right: -35px;
            top: -35px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ccc;
            border-radius: 50%;
            img{
                width: auto;
            }
            @media (max-width: 992px){
                right: 0px;
                top: -55px;
            }
        }
        >h2{
            text-align: center;
            font-size: 28px;
            color: #000;
            margin-bottom: 40px;
        }
        .formRef{
            display: flex;
            margin-bottom: 40px;
            position: relative;
            input{
                flex: 1;
                height: 45px;
                padding: 0 20px;
                outline: none;
                border-radius: 50px;
                border: 1px solid #ccc;
            }
            >button{
                position: absolute;
                right: 0;
                height: 100%;
                border: 1px solid #000;
                background: #000;
                color: white;
                padding: 0 20px;
                border-radius: 50px;
            }
        }
        .responData{
            display: flex;
            justify-content: space-between;
            gap: 10px;
            transition: all .5s ease;
            overflow: hidden;
            height: 0;
            .tableData{
                width: 100%;
                h2{
                    text-align: center;
                    color: #000;
                    font-size: 14px;
                }
                p{
                    background: #000;
                    color: #fff;
                    text-align: center;
                    height: 80px;
                    border-radius: 10px;
                    line-height: 80px;
                }
            }
            &.open{
                height: 105px;
            }
        }
        >h3{
            color: #ed4337;
            text-align: center;
            font-size: 16px;
            height: 0;
            overflow: hidden;
            transition: all .5s ease;
            &.show{
                height: 20px;
            }

        }
        
    }
</style>