<template>
    <div class="modal fade" :class="{ show: showModal }" id="modalRoll" tabindex="-1" aria-labelledby="modalRoll"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content popupRollRadius">
                <button class="close" data-dismiss="modal" aria-label="Close">
                    <img src="../../assets/close.png" alt="">
                </button>
                <div class="modal-body popupRoll">
                    <img class="congra" src="../../assets/lauch.png" alt="">
                    <h2>Congratulations!<br> You've got an NFT</h2>
                    <img class="randomImg" :src="randomPicture" @load="handleImageLoaded" />
                    <div class="modal-buttons">
                        <button @click="reRoll">Re-roll</button>
                        <button @click="buyNow">Buy Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { init, useOnboard } from '@web3-onboard/vue'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseWalletModule from '@web3-onboard/coinbase'

const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const injected = injectedModule()

init({
    wallets: [coinbaseWalletSdk, injected],
    connect: { autoConnectLastWallet: true },
    chains: [
        {
            id: 137,
            // token: 'MATIC',
            // label: 'Polygon',
            // rpcUrl: 'https://polygon-rpc.com'
        },
        {
            id: 80002,
            // token: 'MATIC',
            // label: 'Amoy',
            // rpcUrl: 'https://rpc-amoy.polygon.technology/'
        }
    ]
})

const { connectWallet, connectedWallet } = useOnboard()

import { ethers } from 'ethers'
import abi from './abi.json'

export default {
    props: {
        showModal: {
            type: Boolean,
            required: true,
        },
        randomPicture: {
            type: String,
            required: true,
        },
        currentZodiac: {
            type: Object,
            required: true,
        },
        nft: {
            type: Object,
        }
    },
    methods: {
        reRoll() {
            this.$emit('re-roll');
        },
        async buyNow() {
            this.$emit('buy-now');
        },
        handleImageLoaded() {
            this.$emit('imageLoaded');
        },
    },
}
</script>

<style lang="scss" scoped>
.popupRollRadius {
    border-radius: 16px;
    position: relative;

    .close {
        position: absolute;
        right: -35px;
        top: -10px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ccc;
        border-radius: 50%;
        z-index: 99999;

        img {
            width: auto;
        }

        @media (max-width: 992px) {
            right: 0px;
            top: -55px;
        }
    }
}

.popupRoll {
    text-align: center;

    .congra {
        width: 40%;
        margin: auto;
    }

    >h2 {
        font-size: 24px;
        margin: 20px 0;
    }

    .randomImg {
        width: 80%;
        border-radius: 10px;
        margin: auto;
    }

    .modal-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        gap: 10px;

        button {
            width: 100%;
            border-radius: 50px;
            border: 1px solid;
            padding: 6px 0;
            font-weight: bold;
            font-size: 14px;

            &:first-child {
                border-color: #ccc;
                color: black;
                background: #fff;

                &:hover {
                    background: black;
                    color: #fff;
                    border-color: #000;
                }
            }

            &:last-child {
                background: black;
                color: #fff;
                border-color: #000;

                &:hover {
                    border-color: #ccc;
                    color: black;
                    background: #fff;
                }
            }
        }
    }
}
</style>