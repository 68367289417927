<template>
    <div class="back-home">
        <router-link to="/"><img src="../assets/LOGO.svg" alt="" /></router-link>
        <router-link to="/">Home</router-link>
        <router-link to="/my-nft">My NFT</router-link>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .back-home {
        position: fixed;
        z-index: 999;
        top: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.27);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(7.8px);
        border: 1px solid rgba(0, 0, 0, 0.08);
        top: 0;
        padding: 5px 20px;

        a {
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.5s ease;
            color: #fff;
            font-weight: 500;
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid #fff;
            &:first-child {
                padding-left: 0;
                border-left: 0;
                margin-left: 0;
            }
        }
    }
</style>