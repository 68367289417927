import Vue from "vue";
import App from "./App.vue";
import './assets/styles.css';
import router from "./router";
import store from "./store";
import "slick-carousel/slick/slick.css";
import "animate.css";
import "wowjs/css/libs/animate.css";

import VueToastr2 from "vue-toastr-2";
import "vue-toastr-2/dist/vue-toastr-2.min.css";

import axios from "axios";
import VueAxios from "vue-axios";

import firebaseApp from "./firebase"

window.toastr = require("toastr");

Vue.use(VueToastr2);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';
import ScrollPanel from 'primevue/scrollpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'

import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('Card', Card)
Vue.component('ScrollPanel', ScrollPanel)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)

new Vue({
  router,
  store,
  firebaseApp,
  render: (h) => h(App),
}).$mount("#app");
