<template>
    <div class="section_4">
        <div class="utilityPage">
            <img class="bg_utility" src="../assets/bg_utility.png" alt="">
            <div class="title">
                <h2>NFT Utility</h2>
            </div>
            <div class="boxContent">
                <div class="left">
                    <div class="item item_1" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>Lifetime Premium Access To Crypto Trading App By Zyncas for <span class="cyanBrown">Genesis NFT owner</span></h2>
                                <div class="decription">
                                    <p>Enjoy permanent access to premium features and content in Signals application at no extra cost.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item item_2" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>6 Months Premium Access To Crypto Trading App By Zyncas for <span class="cyanBlue">Zodiac Arena NFT owner</span></h2>
                                <!-- <div class="decription">
                                    <p>Benefit from a built-in auto trading bot for 24/7 automated trading strategies.</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="item item_3" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>$ZCS Token Airdrop</h2>
                                <div class="decription">
                                    <p>Receive ZCS tokens directly as part of an exclusive airdrop.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="imgMain" @mousemove="handleMouseMove">
                    <div class="cardImg" >
                        <img src="../assets/img_utility.png" alt="">
                    </div>
                </div>
                <div class="right">
                    <div class="item item_4" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>$ZCS Private Sales Whitelist</h2>
                                <div class="decription">
                                    <p>Gain access to ZCS private sales with a guaranteed whitelist spot.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item item_5" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>Staking & Lending</h2>
                                <div class="decription">
                                    <p>Utilize your NFTs for staking, lending, or selling on OpenSea for profit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item item_6" @mousemove="handleMouseMove">
                        <div class="line">
                            <div class="content">
                                <h2>DAO Governance & Voting</h2>
                                <div class="decription">
                                    <p>Slash fees, boost staking and referral rewards and enjoy cashback effortlessly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lastLine" >
                <div class="line item_7" @mousemove="handleMouseMove">
                    <div class="content">
                        <h2>Exclusive Access To Auto<br> Trading Bot By Zyncas</h2>
                        <div class="decription">
                            <p>Benefit from a built-in auto trading bot for 24/7 automated trading strategies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {

        },
        data(){
            return{
                width: 0,
                height: 0,
                mouseX: 0,
                mouseY: 0,
                mouseLeaveDelay: null
            }
        },
        mounted() {
            // this.width = this.$refs.card.offsetWidth;
            // this.height = this.$refs.card.offsetHeight;
        },
        methods:{
            handleMouseMove(event){
                const imgMain = document.querySelector('.imgMain img');
                const bg_utility = document.querySelector('.bg_utility');
                const item1 = document.querySelector('.item_1');
                const item2 = document.querySelector('.item_2');
                const item3 = document.querySelector('.item_3');
                const item4 = document.querySelector('.item_4');
                const item5 = document.querySelector('.item_5');
                const item6 = document.querySelector('.item_6');
                const item7 = document.querySelector('.item_7');
                const windowWidth = window.innerWidth / 7;
                const windowHeight = window.innerHeight / 7;
                const mouseX = event.clientX / windowWidth;
                const mouseY = event.clientY / windowHeight;
                imgMain.style.transform = `translate3d(-${mouseX}%, -${mouseY}%, 0)`;
                bg_utility.style.transform = `translate3d(-${mouseY}%, -${mouseX}%, 0)`;
                item1.style.transform = `translate3d(-${mouseX * 2}%, -${mouseY * 2}%, 0)`;
                item2.style.transform = `translate3d(${mouseX * 2}%, ${mouseY * 2}%, 0)`;
                item3.style.transform = `translate3d(-${mouseX * 2}%, -${mouseY * 2}%, 0)`;
                item4.style.transform = `translate3d(-${mouseX * 2}%, -${mouseY * 2}%, 0)`;
                item5.style.transform = `translate3d(${mouseX * 2}%, ${mouseY * 2}%, 0)`;
                item6.style.transform = `translate3d(-${mouseX * 2}%, -${mouseY * 2}%, 0)`;
                item7.style.transform = `translate3d(-${mouseX * 2}%, -${mouseY * 2}%, 0)`;

            }
        },
    }
</script>

<style lang="scss" scoped>
    @keyframes animate{
        0%{
            transform: translate(-50%, -50%) rotate(0deg);
        } 
        100%{
            transform: translate(-50%, -50%) rotate(360deg);
        }   
    }
    .utilityPage{
        display: flex;
        min-block-size: 100vh;
        padding-bottom: 30px;
        width: 100%;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 86px;
        flex-direction: column;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        @media (min-width: 1440px){
            padding-top: 0;
        }
        @media (max-width: 992px){
            height: auto;
            padding-top: 0;
            .title{
                margin-bottom: 40px;
            }
        }
        img.bg_utility{
            position: absolute;
            z-index: -1;
        }
        .title{
            display: none;
        }
        .boxContent{
            display: flex;
            justify-content: space-between;
            width: 90%;
            margin: auto;
            h2{
                font-size: 16px;
                font-weight: bold;
                margin: 0;
                position: relative;
                z-index: 1;
                cursor: default;
                span{
                    &.cyanBrown{
                        color: #FF9900;
                    }
                    &.cyanBlue{
                        color: #00ffff;
                    }
                }
            }
            .item{
                background: #FFFFFF;
                box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.12);
                text-align: center;
                height: 72px;
                padding: 0 15px;
                position: relative;
                transition: .5s;
                width: 315px;
                z-index: 10;
                .line{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    inset: 0;
                    background: #d5d5d5;
                    transition: all .5s ease;
                    height: 72px;
                    .content{
                        position: relative;
                        .decription{
                            position: absolute;
                            width: 250px;
                            display: flex;
                            align-items: center;
                            left: 0;
                            padding: 15px;
                            background: #fff;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                            color: #000;
                            border-radius: 16px;
                            top: 100px;
                            opacity: 0;
                            visibility: hidden;
                            transition: all .5s ease;
                            z-index: -1;
                            p{
                                margin-bottom: 0;
                            }
                        }
                    }
                    &:hover{
                        box-shadow: 0px 0px 13.212px rgba(63, 255, 95, 0.7), 0px 0px 5.911px rgba(63, 243, 255, 0.7);
                        .content{
                            .decription{
                                top: 50px;
                                opacity: 1;
                                visibility: visible;
                                z-index: 999;
                            }
                        }   
                    }
                }
            }
            .left{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                .item{
                    &:not(:nth-child(2)){
                        margin-right: -50px;
                        margin-left: 50px;
                    }
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: right;
                .item{
                    &:not(:nth-child(2)){
                        margin-left: -50px;
                        margin-right: 50px;

                    }
                }
            }
            .imgMain{
                img{
                    // transition: 1s cubic-bezier(0.445, 0.05, 0.55, 0.95);
                
                }
            }
        }
        .lastLine{
            text-align: center;
            margin-top: -100px;
            h2{
                font-size: 16px;
                font-weight: bold;
                margin: 0;
                position: relative;
                z-index: 1;
                cursor: default;
                
            }
            .line{
                display: flex;
                align-items: center;
                justify-content: center;
                inset: 0;
                background: #d5d5d5;
                transition: all .5s ease;
                height: 72px;
                width: 315px;
                .content{
                    position: relative;
                    .decription{
                        position: absolute;
                        width: 250px;
                        display: flex;
                        align-items: center;
                        left: 0;
                        padding: 15px;
                        background: #fff;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                        color: #000;
                        border-radius: 16px;
                        top: 100px;
                        z-index: -1;
                        opacity: 0;
                        visibility: hidden;
                        transition: all .5s ease;
                        p{
                            margin-bottom: 0;
                        }
                    }
                }
                &:hover{
                    box-shadow: 0px 0px 13.212px rgba(63, 255, 95, 0.7), 0px 0px 5.911px rgba(63, 243, 255, 0.7);
                    .content{
                        .decription{
                            top: 50px;
                            opacity: 1;
                            visibility: visible;
                            z-index: 999;
                        }
                    }   
                }
            }
            @media (max-width: 992px){
                margin-top: -25px;
                .line.item_7{
                    transform: unset !important;
                }
            }

        }
        @media (max-width: 992px){
            padding-top: 0px;
            flex-direction: column;
            padding-bottom: 100px;
            img.bg_utility{
                display: none;
            }
            .title{
                display: block;
                text-align: left;
                width: 100%;
                padding-left: 20px;
                border-top: 0.5px solid rgba(0, 0, 0, 0.5);
                padding-top: 50px;
                h2{
                    font-size: 48px;
                    font-family: 'Neboma Sporte';
                    color: #000;
                    margin: 0;
                }
            }
            .boxContent{
                flex-direction: column;
                justify-content: center;
                gap: 20px;
                .item{
                    height: auto;
                    background: transparent;
                    padding: 0;
                    .line{
                        height: auto;
                        position: static;
                        display: block;
                        &::after, &::before{
                            display: none;
                        }
                        .content{
                            >h2{
                                height: 70px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .decription{
                                width: 80%;
                                left: 50%;
                                translate: -50%;
                                height: 126px;
                                top: -80px !important;
                                &::after{
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 7px solid transparent;
                                    border-right: 7px solid transparent;
                                    border-top: 7px solid white;
                                    position: absolute;
                                    bottom: -7px;
                                    left: 50%;
                                    translate: -50%;
                                }

                            }
                        }
                    }
                    &:hover{
                        .line{
                            .content{
                                .decription{
                                    top: -120px !important;
                                }
                            }
                        }
                    }
                }
                .imgMain{
                    display: none;
                }
                .left, .right{
                    align-items: center;
                    gap: 20px;
                    .item{
                        margin: 0 !important;
                        transform: unset !important;
                    }
                }
            }
            .lastLine{
                margin-top: 20px;
                .line{
                    .content{
                        >h2{
                            height: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .decription{
                            width: 95%;
                            left: 50%;
                            translate: -50%;
                            height: 126px;
                            top: -80px !important;
                            &::after{
                                content: "";
                                width: 0;
                                height: 0;
                                border-left: 7px solid transparent;
                                border-right: 7px solid transparent;
                                border-top: 7px solid white;
                                position: absolute;
                                bottom: -7px;
                                left: 50%;
                                translate: -50%;
                            }
                            p{
                                font-size: 15px;
                            }
    
                        }
                    }
                    &:hover{
                        .content{
                            .decription{
                                top: -120px !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>