import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HowToBuy from "../views/HowToBuy.vue"
import BuyNFT from "../views/BuyNFT.vue"
import MyNFT from "../views/MyNFT.vue"
import MiniGame from "../views/MiniGame.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/how-to-buy",
    name: "HowToBuy",
    component: HowToBuy,
  },
  {
    path: "/buy-nft",
    name: "BuyNFT",
    component: BuyNFT,
  },
  {
    path: "/my-nft",
    name: "MyNFT",
    component: MyNFT,
  },
  {
    path: "/minigame",
    name: "MiniGame",
    component: MiniGame,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
