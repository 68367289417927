<template>
    <div class="layoutMyNft">
        <FlashMessage :position="'right bottom'" />
        <div class="layoutBoxMyNft">
            <div v-if="nfts && connectedWallet.value != null">
                <h2>My NFTs</h2>

                <div class="listMyNft">
                    <div class="itemNft" v-for="(nft, index) in nfts" :key="index">
                        <img :src="nft.img" />
                        <!-- <p>{{ nft.name }}</p> -->
                        <p>Zyncas Zodiac #24</p>
                        <button disabled>Claim</button>
                    </div>
                </div>
            </div>
            <div v-else>
                <button class="connectWallet" @click="connectWallet">Connect Wallet to see your NFTs</button>
            </div>
        </div>
        <!-- <Loading :start="!!loading" /> -->
    </div>
</template>

<script>
// Import the functions you need from the SDKs you need
import { getFirestore, getDocs, collection, query, where } from "firebase/firestore";
import Web3 from 'web3'
import sha3 from 'js-sha3'
import firebaseApp from '../firebase'

// Initialize Cloud Firestore and get a reference to the service
let db
if (process.env.NODE_ENV == 'development') {
    db = getFirestore(firebaseApp, "development");
} else {
    db = getFirestore(firebaseApp);
}

import { init, useOnboard } from '@web3-onboard/vue'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseWalletModule from '@web3-onboard/coinbase'

const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const injected = injectedModule()

init({
    wallets: [coinbaseWalletSdk, injected],
    connect: { autoConnectLastWallet: true },
    chains: process.env.NODE_ENV == 'development' ?
        [
            {
                id: 80002,
                token: 'MATIC',
                label: 'POLYGON AMOY TESTNET',
                rpcUrl: 'https://rpc-amoy.polygon.technology/'
            }
        ] :
        [
            {
                id: 137,
                token: 'MATIC',
                label: 'Polygon',
                rpcUrl: 'https://polygon-rpc.com'
            }
        ]
})

const { connectWallet, connectedWallet } = useOnboard()

export default {
    data() {
        return {
            nfts: [],
            connectedWallet: connectedWallet
        }
    },
    methods: {
        async connectWallet() {
            await connectWallet()
        }
    },
    async mounted() {
        const hash = (string) => {
            const uintValue = Web3.utils
                .toBigInt("0x" + sha3.keccak256(string).toString("hex"))
                .toString();
            return uintValue.substring(0, 10);
        };

        const q = query(collection(db, "boughtNFTs"), where("userID", "==", connectedWallet.value.address));

        const querySnapshot = await getDocs(q);
        let nfts = []
        querySnapshot.forEach((doc) => {
            const data = doc.data()

            if (data.bg == undefined || data.back == undefined || data.body == undefined || data.head == undefined) return

            const hashCode = hash(`${data.bg}${data.back}${data.body}${data.head}`);
            nfts.push({
                name: data.name,
                img: `https://firebasestorage.googleapis.com/v0/b/signals-61284.appspot.com/o/zyncas_NFT_images%2F${data.head}%2F720x720%2Fzyncas_NFT_${hashCode}_720x720.png?alt=media`,
            })
        });
        this.nfts = nfts
        console.log('check', this.nfts)
    },
}
</script>

<style lang="scss" scoped>
.layoutMyNft {
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;

    .layoutBoxMyNft {
        .connectWallet {
            position: fixed;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
            background: #000;
            color: #fff;
            border: 0;
            border-radius: 8px;
            padding: 10px 20px;
        }

        h2 {
            color: #fff;
            font-size: 18px;
            margin-top: 15px;
            text-align: center;
        }

        .listMyNft {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            @media (max-width: 552px) {
                gap: 10px;
            }

            .itemNft {
                width: calc((100% / 5) - 16px);

                @media (max-width: 992px) {
                    width: calc((100% / 4) - 16px);
                }

                @media (max-width: 552px) {
                    width: calc((100% / 3) - 7px);
                }

                border-radius: 10px;

                img {
                    width: 100%;
                    border-radius: 10px;
                }

                p {
                    text-align: center;
                    color: #fff;
                    font-weight: bold;
                    margin: 10px 0;

                    @media (max-width: 552px) {
                        font-size: 12px;
                    }
                }

                button {
                    border: 0px;
                    width: 100%;
                    border-radius: 50px;
                    background: #fff;
                    padding: 5px 0;
                    transition: all .5s ease;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    @media (max-width: 552px) {
                        padding: 3px;
                    }

                    &:hover {
                        background: #000;
                        color: #fff;
                    }

                    &:disabled {
                        background: #cecece;

                        &:hover {
                            cursor: no-drop;
                        }
                    }
                }
            }
        }
    }
}
</style>
