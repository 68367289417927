<template>
    <div class="section_6">
        <div class="teamPage">
            <div class="title">
                <h2>About team</h2>
            </div>
            <div class="slideTeam">
                <slick ref="slick" :options="slickOptionsTeam">
                    <div class="item" v-for="item in listTeam" :key="item.id">
                        <img :src="require('../assets/' + item.imgUrl)" alt="">
                        <h2>{{ item.name }}</h2>
                        <p>{{ item.title }}</p>
                        <ul>
                            <li v-if="item.tele"><a :href="item.tele" target="_balnk"><img src="../assets/telegram.png" alt=""></a></li>
                            <li v-if="item.twitter"><a :href="item.twitter" target="_balnk"><img src="../assets/twitter.png" alt=""></a></li>
                            <li v-if="item.linkedin"><a :href="item.linkedin" target="_balnk"><img src="../assets/linkedin.png" alt=""></a></li>
                            <li v-if="item.facebook"><a :href="item.facebook" target="_balnk"><img src="../assets/fb.png" alt=""></a></li>
                        </ul>
                    </div>
                </slick>
            </div>
        </div>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        components: {
            Slick
        },
        data(){
            return{
                slickOptionsTeam: {
                    slidesToShow: 5,
                    dots: true,
                    autoplay: false,
                    infinite: false,
                    speed: 1000,
                    prevArrow: '<button class="btn_slick prev"><ion-icon name="arrow-back-outline"></ion-icon></button>',
                    nextArrow: '<button class="btn_slick next"><ion-icon name="arrow-forward-outline"></ion-icon></button>',
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 1440,
                            settings: {
                                slidesToShow: 4,
                            },
                        },
                        {
                            breakpoint: 1200,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            },
                        },
                    ]
                },
                listTeam:[
                    {
                        id: 1,
                        name: 'Hieu Hiep Nguyen',
                        imgUrl: 'hiep.jpg',
                        title: 'CEO/Founder - CTO',
                        tele: 'https://t.me/tieushinshin',
                        twitter: 'https://twitter.com/tieushinshin',
                        linkedin: '',
                        facebook: 'https://www.facebook.com/hieuhiep.nguyen.7',
                    },
                    {
                        id: 2,
                        name: 'Demi Tran',
                        imgUrl: 'dieu.jpg',
                        title: 'Marketing Leader',
                        tele: 'https://t.me/NhatDieuTran',
                        twitter: '',
                        linkedin: 'https://www.linkedin.com/in/nhat-dieu-tran-aab6b913a/',
                        facebook: '',

                    },
                    
                    {
                        id: 3,
                        name: 'Duy Bui',
                        imgUrl: 'duy.jpg',
                        title: 'Android Developer',
                        tele: 'https://t.me/duybui269',
                        twitter: '',
                        linkedin: '',
                        facebook: '',

                    },
                    {
                        id: 4,
                        name: 'Kiet Doan',
                        imgUrl: 'kiet.jpg',
                        title: 'IOS Developer',
                        tele: 'https://t.me/DKiet',
                        twitter: '',
                        linkedin: '',
                        facebook: '',

                    },
                    {
                        id: 5,
                        name: 'Trong Dang',
                        imgUrl: 'trong.jpg',
                        title: 'Backend Developer',
                        tele: 'https://t.me/DangTrong',
                        twitter: '',
                        linkedin: '',
                        facebook: '',

                    },
                    {
                        id: 6,
                        name: 'Tom Tran',
                        imgUrl: 'tom.jpg',
                        title: 'Frontend Developer',
                        tele: 'https://t.me/tomtran0704',
                        twitter: '',
                        linkedin: '',
                        facebook: '',

                    },
                    {
                        id: 7,
                        name: 'Hoang Pham Minh',
                        imgUrl: 'hoang.jpg',
                        title: 'Principal Artist',
                        tele: '',
                        twitter: '',
                        linkedin: '',
                        facebook: '',

                    },
                    
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .teamPage{
        padding: 0px 0px 0;
        height: 100vh;
        .title{
            padding: 35px 60px 20px;
            h2{
                font-size: 62px;
                font-family: 'Neboma Sporte';
                font-weight: bold;
                margin: 0;
            }
        }
        .slideTeam{
            padding: 0 45px;
            height: calc(100vh - 228px);
            position: relative;
            .slick-dots{
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;
            }
            .item{
                padding: 15px;
                >img{
                    width: 100%;
                    margin-bottom: 30px;
                }
                h2{
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 24px;
                    margin-bottom: 8px;
                    position: relative;
                    &:after{
                        content: '';
                        position: absolute;
                        width: 15%;
                        height: 1px;
                        left: 0;
                        background: rgba(0, 0, 0, 0.5);
                        bottom: -5px;
                        display: inline-block;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 12px;
                }
                ul{
                    display: flex;
                    gap: 20px;
                    >li{
                        a{
                            // border: 1px solid #000;
                            // border-radius: 50%;
                            display: inline-block;
                            width: 40px;
                            aspect-ratio: 1;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                width: 25px;
                            }
                        }
                    }
                }
            }
        }
        @media (max-width: 992px){
            border-top: 0.5px solid rgba(0, 0, 0, 0.5);
            padding-top: 50px;
            .title{
                margin-bottom: 50px;
                width: 100%;
                padding: 0;
                padding-left: 20px;
                padding-top: 10px;
                h2{
                    font-size: 48px;
                    margin: 0;
                }
            }
            .slideTeam{
                height: calc(100% - 70px);
                padding: 0 20px;
            }
        }
    }
</style>