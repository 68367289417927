<template>
    <main class="buyNft_view">
        <MenuNft />
        <BuyNftView />
    </main>
</template>

<script>
import BuyNftView from "@/components/BuyNft/BuyNftView.vue";
import MenuNft from "@/components/MenuNft.vue";

export default {
    name: "HowToBuy",
    components: {
        BuyNftView,
        MenuNft
    },
}
</script>

<style lang="scss" scoped>
.buyNft_view {
    min-height: 100vh;
    background: url(../assets/bg_1.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;
}
</style>