<template>
    <main class="buyNft_view">
        <!-- <div class="back-home">
            <router-link to="/"><img src="../assets/LOGO.svg" alt="" /></router-link>
            <router-link to="/">Home</router-link>
        </div> -->
        <MenuNft />
        <MyNFT />
    </main>
</template>

<script>
import MyNFT from "@/components/MyNFT.vue";
import MenuNft from "@/components/MenuNft.vue";
export default {
    name: "MyNFTView",
    components: {
        MyNFT,
        MenuNft
    },
}
</script>

<style lang="scss" scoped>
.buyNft_view {
    min-height: 100vh;
    background: url(../assets/bg_1.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 100px;

    
}
</style>