<template>
    <div class="vue-winwheel">
        <Header />
        <div class="minigame">
            <div class="wheel-wrapper">
                <div class="canvas-wrapper">
                    <canvas id="canvas" width="500" height="500">
                        <p style="{color: white}" align="center">Sorry, your browser doesn't support canvas.
                            Please try
                            Google Chrome.</p>
                    </canvas>
                </div>
                <div class="button-wrapper">
                    <div v-if="!loadingTwitterUser && !twitterUser">
                        <span class="message">Login with your Twitter account to play the game</span>
                        <div class="cta-wrapper">
                            <button class="cta" @click="loginTwitter">Login</button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="cta-wrapper" v-if="!loadingPrize && !wheelSpinning && canSpin">
                            <button class="cta" @click.prevent="startSpin()">SPIN</button>
                        </div>
                        <span class="message" v-else>{{ cannotSpinReason }}</span>
                    </div>
                </div>
            </div>
            <div class="info">
                <div class="milestones">
                    <div class="progress_nft">
                        <h2>Your Points</h2>
                        <div class="progress_bar">
                            <div class="current_bar" :style="progressStyle" />
                            <span>{{ currentMilestone }}/30</span>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <TabView class="rewards">
                        <TabPanel header="Milestone Rewards">
                            <DataTable :value="milestoneRewards" :scrollable="true" scrollHeight="21rem"
                                :loading="loadingMilestoneRewards">
                                <Column field="points" header="Points" :styles="{ 'min-width': '200px' }">
                                </Column>
                                <Column field="item" header="Reward" :styles="{ 'min-width': '200px' }">
                                </Column>
                                <Column header="Claim" :styles="{ 'min-width': '200px' }">
                                    <template #body="slotProps">
                                        <div
                                            v-if="(slotProps.data.item == 'Whitelist' || slotProps.data.item == '1 lucky ticket to win $200') && slotProps.data.id">
                                            <a class="cta"
                                                href="https://discord.com/channels/1224277039411105844/1224305996399116349"
                                                target="_blank">Contact Us</a>
                                        </div>
                                        <div v-else-if="slotProps.data.isReward">
                                            <button class="cta" v-if="!slotProps.data.hasClaimed"
                                                @click="claimCode(slotProps.data.id, 1)">
                                                Claim</button>
                                            <button v-else class="cta"
                                                @click="showCodeModal = true; code = slotProps.data.code">Show</button>
                                        </div>
                                    </template>
                                </Column>
                                <template #empty>
                                    You haven't won a reward yet
                                </template>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Daily Rewards">
                            <DataTable :value="dailyRewards" :scrollable="true" scrollHeight="21rem"
                                :loading="loadingDailyRewards">
                                <Column header="Time" :styles="{ 'min-width': '200px' }">
                                    <template #body="slotProps">
                                        {{ formatDate(slotProps.data.createdAt) }}
                                    </template>
                                </Column>
                                <Column field="item" header="Reward" :styles="{ 'min-width': '200px' }">
                                </Column>
                                <Column header="Claim" :styles="{ 'min-width': '200px' }">
                                    <template #body="slotProps">
                                        <div v-if="slotProps.data.item == 'NFT Genesis' && slotProps.data.id">
                                            <a class="cta"
                                                href="https://discord.com/channels/1224277039411105844/1224305996399116349"
                                                target="_blank">Contact Us</a>
                                        </div>
                                        <div v-else-if="slotProps.data.isReward">
                                            <button v-if="!slotProps.data.hasClaimed" class="cta"
                                                @click="claimCode(slotProps.data.id, 0)">Claim</button>
                                            <button v-else class="cta"
                                                @click="showCodeModal = true; code = slotProps.data.code">Show</button>
                                        </div>
                                    </template>
                                </Column>
                                <template #empty>
                                    You haven't won a reward yet
                                </template>
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>

                <div class="tasks">
                    <h2>Join our community to get bigger rewards</h2>
                    <p>Complete 3 tasks below to be eligible of receiving our free Genesis Zodiac NFT. 50 lucky
                        winners
                        will be randomly picked at the end of the wheel.</p>
                    <div class="tasks-content">
                        <DataTable :value="tasks" :scrollable="false" scrollHeight="15rem" :loading="loadingTasks">
                            <Column field="task" header="Task" :styles="{ 'min-width': '200px' }">
                                <template #body="slotProps">
                                    <i :class="slotProps.data.icon"></i>
                                    {{ slotProps.data.task }}
                                </template>
                            </Column>
                            <Column field="btn" :styles="{ 'min-width': '200px' }">
                                <template #body="slotProps">
                                    <div class="task-btn">
                                        <a class="cta" :href="slotProps.data.btn.href" target="_blank"
                                            @click="doTask(slotProps.data.idx)">{{
                        slotProps.data.btn.label }}</a>
                                    </div>
                                </template>
                            </Column>
                            <Column field="done" header="Done" :styles="{ 'min-width': '200px' }">
                                <template #body="slotProps">
                                    <i :class="slotProps.data.done ? 'pi pi-check-circle' : 'pi pi-circle'"
                                        :style="slotProps.data.done && 'color: #00f043'"></i>
                                </template>
                            </Column>
                            <!-- <Column field="done" header="Done" :styles="{ 'min-width': '200px' }">
                            <template #body="slotProps">
                                <div
                                    v-if="(slotProps.data.item == 'Whitelist' || slotProps.data.item == '1 lucky ticket to win $200') && slotProps.data.id">
                                    <a class="cta"
                                        href="https://discord.com/channels/1224277039411105844/1224305996399116349"
                                        target="_blank">Contact Us</a>
                                </div>
                                <div v-else-if="slotProps.data.isReward">
                                    <button class="cta" v-if="!slotProps.data.hasClaimed"
                                        @click="claimCode(slotProps.data.id, 1)">
                                        Claim</button>
                                    <button v-else class="cta"
                                        @click="showCodeModal = true; code = slotProps.data.code">Show</button>
                                </div>
                            </template>
                        </Column>
                        <template #empty>
                            You haven't won a reward yet
                        </template> -->
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
        <Dialog :visible.sync="modalPrize" :modal="true" :contentStyle='{ "font-famil": `"Montserrat", sans-serif` }'>
            {{ prizeName == 'Better luck next time' ? 'Better luck next time' : `Congrats! You got a new reward:
            ${prizeName}`
            }}
        </Dialog>
        <Dialog :visible.sync="newCodeModal" :modal="true" :contentStyle='{ "font-famil": `"Montserrat", sans-serif` }'
            containerStyle="min-width: 20rem">
            {{ claimSuccess ? `Code claimed sucessfully: ${code}` : 'Something went wrong! Please contact us' }}
        </Dialog>
        <Dialog :visible.sync="showCodeModal" :modal="true" :contentStyle='{ "font-famil": `"Montserrat", sans-serif` }'
            containerStyle="min-width: 20rem">
            Code: {{ code }}
        </Dialog>
    </div>

</template>

<script type="text/javascript" async src="https://platform.twitter.com/widgets.js"></script>
<script>
import * as Winwheel from './winwheel'
import Header from "@/components/Header.vue";
import Progress from "@/components/Progress.vue"
import moment from 'moment';

import { getAuth, signInWithPopup, TwitterAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
const provider = new TwitterAuthProvider();

const defaultMilestones = [
    { points: 3, item: "1-week code" },
    { points: 10, item: "Whitelist" },
    { points: 20, item: "1-month code" },
    { points: 30, item: "1 lucky ticket to win $200" },
]

const rewardsMap = [4, 7, 9, 1, 2, 3, 5, 6, 8]

export default {
    name: 'VueWinWheel',
    components: {
        Header,
        Progress,
    },
    props: {
        segments: {
            default() {
                return [
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#6225e6',
                        text: '1-week code'
                    },
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#212529',
                        text: '1-month code'
                    },
                    {
                        textFillStyle: '#000',
                        fillStyle: '#00f043',
                        text: '100$ cash'
                    },
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#6225e6',
                        text: 'Better luck next time'
                    },
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#212529',
                        text: 'NFT Genesis'
                    },
                    {
                        textFillStyle: '#000',
                        fillStyle: '#00f043',
                        text: 'Lifetime code'
                    },
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#6225e6',
                        text: 'Better luck next time'
                    },
                    {
                        textFillStyle: '#fff',
                        fillStyle: '#212529',
                        text: '200$ cash'
                    },
                    {
                        textFillStyle: '#000',
                        fillStyle: '#00f043',
                        text: 'Better luck next time'
                    },
                ]
            }
        }
    },
    data() {
        return {
            doneX: false,
            doneDiscord: false,
            doneTele: false,
            tasks: [
                {task: "Follow Us On Twitter", idx: 0, icon:"pi pi-twitter", btn: {href: "https://twitter.com/Zodiac_Arena", label:"Follow"}, done: false},
                {task: "Join Our Discord Channel", idx: 1, icon:"pi pi-discord", btn: {href: "https://discord.gg/zodiac-arena", label:"Join"}, done: false},
                {task: "Join Our Telegram Channel", idx: 2, icon:"pi pi-telegram", btn: {href: "https://t.me/signalscryptoglobal", label:"Join"}, done: false},
            ],
            loadingTasks: false,
            twitterUser: null,
            loadingTwitterUser: false,
            loadingPrize: false,
            theWheel: null,
            modalPrize: false,
            wheelPower: 1,
            wheelSpinning: false,
            canSpin: false,
            cannotSpinReason: "",
            prizeName: 'Better luck next time',
            milestoneRewards: defaultMilestones.slice(),
            loadingMilestoneRewards: false,
            dailyRewards: [],
            loadingDailyRewards: false,
            code: null,
            claimSuccess: false,
            newCodeModal: false,
            showCodeModal: false,
            WinWheelOptions: {
                textFontSize: 18,
                outterRadius: 410,
                innerRadius: 25,
                lineWidth: 8,
                animation: {
                    type: 'spinOngoing',
                    duration: 1
                }
            },
            currentMilestone: 0
        }
    },
    created() {
        this.loadingTwitterUser = true
        this.auth = getAuth();
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                this.twitterUser = user
                console.log('access token', user.accessToken)
                this.checkIfCanSpin(user.accessToken)
                this.loadMilestoneRewards(user.accessToken)
                this.loadDailyRewards(user.accessToken)
                this.loadTasks()
                // ...
            } else {
                // User is signed out
                // ...
            }
            this.loadingTwitterUser = false
        });
    },
    methods: {
        showPrize() {
            this.modalPrize = true
        },
        hidePrize() {
            this.modalPrize = false
        },
        checkIfCanSpin(accessToken) {
            fetch(
                'https://us-central1-signals-61284.cloudfunctions.net/api/events/turns',
                {
                    method: 'GET',
                    headers: {
                        authorization: accessToken
                    }
                }
            ).then((response) => response.json()).then((data) => {
                if (data.code != 200) {
                    this.cannotSpinReason = data.data
                    this.canSpin = false
                    console.log('cannot spin', this.cannotSpinReason)
                    return
                }

                this.canSpin = true
            }).catch((e) => {
                console.log('validate error', e)
            })
        },
        async startSpin() {
            if (!this.twitterUser) {
                console.log('not login yet')
                return
            }

            if (this.wheelSpinning === false) {


                this.theWheel.startAnimation()
                this.wheelSpinning = true
                this.theWheel = new Winwheel.Winwheel({
                    ...this.WinWheelOptions,
                    numSegments: this.segments.length,
                    segments: this.segments,
                    animation: {
                        type: 'spinToStop',
                        duration: 5,
                        spins: 5,
                        callbackFinished: this.onFinishSpin
                    }
                })

                console.log('start fetch')
                var prizeNumber = 0
                try {
                    const response = await fetch(
                        'https://us-central1-signals-61284.cloudfunctions.net/api/events/random',
                        {
                            method: 'GET', // Change to 'POST', 'PUT', etc. if needed
                            headers: {
                                'Content-Type': 'application/json',
                                'authorization': this.twitterUser.accessToken,
                            },
                        }
                    )
                    const data = await response.json();
                    if (data.code != 200) {
                        alert('Something went wrong! Please contact us')
                    } else {
                        console.log('prize', data)
                        prizeNumber = rewardsMap[data.data.id]
                    }
                } catch (e) {
                    console.log('random prize error', e)
                }

                console.log('fetch done')

                // example input prize number get from Backend
                // Important thing is to set the stopAngle of the animation before stating the spin.

                // var prizeNumber = Math.floor(Math.random() * this.segments.length) // or just get from Backend
                // prizeNumber = 3

                var stopAt = 360 / this.segments.length * prizeNumber - 360 / this.segments.length / 2 // center pin
                // var stopAt = 360 / this.segments.length * prizeNumber - Math.floor(Math.random() * 60) //random location
                this.theWheel.animation.stopAngle = stopAt
                this.theWheel.startAnimation()
            }
        },
        resetWheel() {
            this.theWheel = new Winwheel.Winwheel({
                ...this.WinWheelOptions,
                numSegments: this.segments.length,
                segments: this.segments
            })

            if (this.wheelSpinning) {
                this.theWheel.stopAnimation(false) // Stop the animation, false as param so does not call callback function.
            }

            this.theWheel.rotationAngle = 0 // Re-set the wheel angle to 0 degrees.
            this.theWheel.draw() // Call draw to render changes to the wheel.
            this.wheelSpinning = false // Reset to false to power buttons and spin can be clicked again.
        },
        initSpin() {
            this.loadingPrize = true
            this.resetWheel()
            this.loadingPrize = false
        },
        onFinishSpin(indicatedSegment) {
            this.prizeName = indicatedSegment.text
            this.showPrize()
            this.wheelSpinning = false
            this.checkIfCanSpin(this.twitterUser.accessToken)
            this.loadMilestoneRewards(this.twitterUser.accessToken)
            this.loadDailyRewards(this.twitterUser.accessToken)
        },
        loginTwitter() {
            signInWithPopup(this.auth, provider)
                .then((result) => {
                    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                    // You can use these server side with your app's credentials to access the Twitter API.
                    const credential = TwitterAuthProvider.credentialFromResult(result);
                    // const token = credential.accessToken;
                    // const secret = credential.secret;

                    // The signed-in user info.
                    const user = result.user;
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                    this.twitterUser = user
                    console.log('access token', user.accessToken)
                    this.checkIfCanSpin(user.accessToken)
                    this.loadMilestoneRewards(user.accessToken)
                    this.loadDailyRewards(user.accessToken)
                    this.loadTasks()
                }).catch((error) => {
                    // Handle Errors here.
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    // The email of the user's account used.
                    // const email = error.customData.email;
                    // The AuthCredential type that was used.
                    // const credential = TwitterAuthProvider.credentialFromError(error);
                    // ...
                });
        },
        loadMilestoneRewards(accessToken) {
            this.loadingMilestoneRewards = true
            fetch(
                'https://us-central1-signals-61284.cloudfunctions.net/api/events/milestone-rewards',
                {
                    headers: {
                        "Content-Type": 'application/json',
                        'authorization': accessToken
                    }
                }
            )
                .then(response => response.json())
                .then(data => {
                    if (data.code != 200) {
                        console.log('load milestone rewards failed', data)
                        return
                    }

                    let rewards = data.data
                    rewards.sort((a, b) => a.points - b.points);
                    let milestoneRewards = defaultMilestones.slice()

                    for (let i = 0; i < milestoneRewards.length; i++) {
                        for (let j = 0; j < rewards.length; j++) {
                            if (milestoneRewards[i].points == rewards[j].points) {
                                milestoneRewards[i] = rewards[j]
                                break
                            }
                        }
                    }

                    this.milestoneRewards = milestoneRewards

                    fetch(
                        'https://us-central1-signals-61284.cloudfunctions.net/api/events/rewards-history',
                        {
                            headers: {
                                'authorization': accessToken
                            }
                        }
                    )
                        .then(response => response.json())
                        .then(data => {
                            if (data.code != 200) {
                                console.error('get rewards history error', data)
                                return
                            }

                            let milestoneRewards = this.milestoneRewards.slice()

                            for (let i = 0; i < milestoneRewards.length; i++) {
                                for (let j = 0; j < data.data.length; j++) {
                                    if (milestoneRewards[i].id && milestoneRewards[i].id == data.data[j].rewardId) {
                                        milestoneRewards[i].code = data.data[j].code
                                    }
                                }
                            }

                            this.milestoneRewards = milestoneRewards
                        })
                })
                .finally(() => this.loadingMilestoneRewards = false)
        },
        loadDailyRewards(accessToken) {
            this.loadingDailyRewards = true
            fetch(
                'https://us-central1-signals-61284.cloudfunctions.net/api/events/daily-rewards?page=1&pageSize=30',
                {
                    headers: {
                        "Content-Type": 'application/json',
                        'authorization': accessToken
                    }
                }
            )
                .then(response => response.json())
                .then(data => {
                    if (data.code != 200) {
                        console.log('load daily rewards failed', data)
                        return
                    }

                    this.dailyRewards = data.data
                    this.currentMilestone = data.data.length

                    fetch(
                        'https://us-central1-signals-61284.cloudfunctions.net/api/events/rewards-history',
                        {
                            headers: {
                                'authorization': accessToken
                            }
                        }
                    )
                        .then(response => response.json())
                        .then(data => {
                            if (data.code != 200) {
                                console.error('get rewards history error', data)
                                return
                            }

                            let dailyRewards = this.dailyRewards.slice()
                            for (let i = 0; i < this.dailyRewards.length; i++) {
                                for (let j = 0; j < data.data.length; j++) {
                                    if (dailyRewards[i].id && dailyRewards[i].id == data.data[j].rewardId) {
                                        dailyRewards[i].code = data.data[j].code
                                    }
                                }
                            }
                            this.dailyRewards = dailyRewards
                        })
                })
                .finally(() => this.loadingDailyRewards = false)
        },
        claimCode(id, type) {
            console.log('claim code', id)
            fetch(
                `https://us-central1-signals-61284.cloudfunctions.net/api/events/reward-claim/${type}/${id}`,
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': this.twitterUser.accessToken
                    }
                }
            )
                .then(response => response.json())
                .then(data => {
                    if (data.code != 200) {
                        this.claimSuccess = false
                        return
                    }

                    this.claimSuccess = true
                    this.code = data.data.code
                    this.newCodeModal = true
                })
                .catch(e => {
                    console.error('claim code error', id, e)
                    this.claimSuccess = false
                    this.newCodeModal = true
                })
                .finally(() => type == 0 ? this.loadDailyRewards(this.twitterUser.accessToken) : this.loadMilestoneRewards(this.twitterUser.accessToken))
        },
        formatDate(timestamp) {
            moment.locale(navigator.language || 'en-US');
            return moment(timestamp * 1000).format('DD/MM/YYYY - HH:mm');
        },
        doTask(taskIdx) {
            console.log('Do task', taskIdx)
            fetch(
            `https://us-central1-signals-61284.cloudfunctions.net/api/events/tasks/${taskIdx}`,
            {
                method: "POST",
                headers: {
                'authorization': this.twitterUser.accessToken
                }
            }
            ).finally(setTimeout(this.loadTasks, 1000))
        },
        loadTasks() {
            console.log('load tasks')
            fetch(
            "https://us-central1-signals-61284.cloudfunctions.net/api/events/tasks",
            {
            headers: {
            'authorization': this.twitterUser.accessToken
            }
            }
            )
            .then(response => response.json())
            .then(data => {if (data.code == 200) {
                this.tasks[0].done = data.data[0] === true
                this.tasks[1].done = data.data[1] === true
                this.tasks[2].done = data.data[2] === true
            }})
        }
    },
    computed: {
        progressStyle() {
            const percentage = (this.currentMilestone / 30) * 100;
            return `width: ${percentage}%;`;
        },
    },
    updated() { },
    mounted() {
        this.initSpin()
    },
}

</script>

<style lang="scss" scoped>
.minigame {
    display: flex;
    min-height: 100vh;
    align-items: center;
    padding: 0 60px;
    gap: 5rem;

    .wheel-wrapper {
        position: relative;
        width: 450px;
    }

    .info {
        margin-top: 8rem;
        flex: 1;
    }

    .tasks {
        margin-top: 3rem;
        text-align: left;

        >h2 {
            color: rgba(255, 255, 255, 0.87);
            margin: 0;
            font-size: 18px;
            margin-bottom: 0.5rem;
        }

        >p {
            color: rgba(255, 255, 255, 0.87);
            font-size: 14px;
        }

        >span {
            color: rgba(255, 255, 255, 0.87);
            margin: 0;
            font-size: 14px;
        }

        .tasks-content {
            margin-top: 1rem;
        }

        .task-btn {
            width: 8rem;

            >a {
                text-decoration: none;
                outline: none;
            }
        }

        .tasks-content {
            height: 20rem;
            padding: 1rem;
            background-color: #fff;
            border-radius: .25rem;
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
        padding: 0 20px;
        padding-top: 150px;

        .info {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        .wheel-wrapper {
            width: 100%;
        }
    }

    .p-component {
        font-family: "Montserrat", sans-serif !important;
    }

    .p-component-overlay {
        font-family: "Montserrat", sans-serif !important;
    }

    .message {
        color: rgba(255, 255, 255, 0.87);
    }
}

:deep(.p-dialog-content) {
    font-size: x-large;
    font-family: "Montserrat", sans-serif !important;
}

:deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    color: #6225e6;
    border-color: #6225e6;
}

:deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
    box-shadow: none;
}

.cta-wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
}

.card {
    padding: 1rem;
    height: 28rem;
}

.progress_nft {
    display: flex;
    width: 100%;
    margin: auto;
    align-items: center;
    gap: 20px;
    margin-bottom: 2rem;

    @media (max-width: 992px) {
        width: 100%;
        padding: 0 20px;
    }

    >h2 {
        color: rgba(255, 255, 255, 0.87);
        margin: 0;
        font-size: 18px;
    }

    .progress_bar {
        flex: 1;
        background: #ccc;
        position: relative;
        height: 20px;
        border-radius: 50px;
        // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        span {
            font-size: 12px;
            line-height: 20px;
            display: inline-block;
            z-index: 10;
            position: relative;
            font-weight: bold;
            color: #000;
        }

        .current_bar {
            position: absolute;
            transition: width 0.3s ease-in-out;
            z-index: 7;
            left: 0px;
            top: 0px;
            height: 20px;
            background: #00f043;
            border-radius: 50px;

        }
    }
}

.vue-winwheel {
    text-align: center;
    background-image: url('/static/img/obstacle-run/bg-spinner-mobile.svg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
}

.vue-winwheel h1 {
    color: #b32656;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 36px;
    line-height: 90px;
    letter-spacing: 4px;
    margin: 0;
}

.vue-winwheel h2 {
    margin: 0;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content {
    width: calc(100vw - 30px);
    padding-top: 52px;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content h2 {
    text-transform: uppercase;
    color: #b32656;
    margin-bottom: 16px;
    margin-top: 0;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 18px;
    letter-spacing: 1.1px;
    margin: 0;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content p {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: black;
    text-align: center;
    line-height: 25px;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content p strong {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content .modal-dismiss {
    top: 12px;
    right: 12px;
}

.vue-winwheel #modalSpinwheel.custom-modal .content-wrapper .content .modal-dismiss i.icon_close {
    font-size: 30px;
    color: #da2a52;
}

.vue-winwheel canvas#canvas {
    position: relative;
    aspect-ratio: 1/1;
    display: block;
    width: 100%;
}

.vue-winwheel .canvas-wrapper {
    position: relative;
    aspect-ratio: 1/1;
}

.vue-winwheel .canvas-wrapper:after {
    content: '';
    display: block;
    width: 42px;
    background: #c4376f;
    height: 42px;
    position: absolute;
    left: calc(50% - 25px);
    margin: auto;
    border-radius: 100%;
    top: calc(50% - 29px);
    border: 5px solid white;
    box-sizing: content-box;
}

.vue-winwheel .canvas-wrapper:before {
    content: '';
    display: block;
    width: 100%;
    background: #0f0f0f;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 100%;
    top: 0;
    aspect-ratio: 1 / 1;
}


.vue-winwheel .wheel-wrapper:before {
    content: '';
    width: 62px;
    height: 47px;
    position: absolute;
    top: -10px;
    left: calc(50% - 31px);
    right: 0;
    display: block;
    z-index: 50;
    background-image: url('../../assets/spinner-marker.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.vue-winwheel .wheel-wrapper .button-wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 231px;
    height: 118px;
}

.vue-winwheel .wheel-wrapper .btn.btn-play {
    padding: 0 58px !important;
    background: #c4376f;
    height: 40px;
    line-height: 40px;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 2px;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
}

.modal {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content {
    text-align: center;
}
</style>