<template>
    <div class="section_3">
        <div class="content_left">
            <div class="top">
                <h2>Gladiators</h2>
                <p>There is a vast amount of gladiators in the Zodiac Arena. Although it may seem chaotic to the
                    newcomer, you can still tell them apart from the way they look. Let us scan through these 5 kinds of
                    gladiators.</p>
            </div>
            <div class="middle">
                <div class="slideWarrior">
                    <slick class="sliderFor" id="c1" :options="slickOptionsWarrios">
                        <div class="imgSlide" v-for="item in itemWarriors" :key="item.id">
                            <img :src="require('../assets/' + item.imgUrl)" alt="">
                        </div>
                    </slick>
                </div>
            </div>
            <div class="bot">
                <p :class="index == 0 ? 'show' : ''">Former members of the armed forces, well-trained in combat and
                    survival skills, proficient in various types of weapons. Without doubts, they are the most dangerous
                    group to face in the arena.</p>
                <p :class="index == 1 ? 'show' : ''">No training, no rules, no hesitation. They come from the
                    streetgangs,
                    extremely short tempered and can easily be triggered. Their strenght, however, comes from their
                    spirit of unity, the gang members always look out for each other.</p>
                <p :class="index == 2 ? 'show' : ''">Deciples of mysterious martial arts schools, hidden deep in the
                    forests, high up on the mountains, away from the modern world. They come to the Zodiac Arena not for
                    the rewards, but for the challenge of their own destiny, and the glory of their school.</p>
                <p :class="index == 3 ? 'show' : ''">They used to be businessmen, politicians, even country leaders.
                    It’s
                    the ability to manipulate minds and control resources that make them powerful. Even with no combat
                    skills, they can still flip the arena upside down without having to step their foot on the field.
                </p>
                <p :class="index == 4 ? 'show' : ''">Spoiled kids sent to the Arena by their schools, or their own
                    parents, as a kind of “Hard Education”. Please don’t be deceived by their adorable and innocent
                    looking appearances, as they are the most unpredictable group of gladiators in the Arena.</p>
            </div>
        </div>
        <div class="content_right">
            <div class="title">
                <h2>Gladiators</h2>
            </div>
            <slick class="sliderNav" id="c2" :options="slickOptionsMenu" @afterChange="changeItem">
                <div class="titleMenu" v-for="item in itemWarriors" :key="item.id">
                    <h2>{{ item.title }}</h2>
                </div>
            </slick>
        </div>
    </div>
</template>

<script>
import Slick from 'vue-slick';
export default {
    components: {
        Slick,
    },
    data() {
        return {
            c1: undefined,
            c2: undefined,
            index: 0,
            slickOptionsWarrios: {
                slidesToShow: 3,
                swipe: false,
                centerMode: true,
                centerPadding: '0',
                dots: false,
                autoplay: false,
                infinite: false,
                // speed: 1000,
                prevArrow: false,
                nextArrow: false,
                focusOnSelect: true,
                asNavFor: '#c2',
                responsive: [
                    {
                        breakpoint: 552,
                        settings: {
                            slidesToShow: 1,
                        },
                    },

                ]
            },
            slickOptionsMenu: {
                asNavFor: '#c1',
                centerMode: true,
                focusOnSelect: true,
                slidesToShow: 5,
                prevArrow: false,
                nextArrow: false,
                dots: false,
                autoplay: false,
                infinite: false,
                vertical: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            vertical: false,
                            slidesToShow: 3,
                            centerMode: false,
                        },
                    },

                ]
            },
            itemWarriors: [
                {
                    id: 1,
                    imgUrl: 'wr_1.png',
                    title: 'The pros',
                },
                {
                    id: 2,
                    imgUrl: 'wr_2.png',
                    title: 'The gangs',
                },
                {
                    id: 3,
                    imgUrl: 'wr_3.png',
                    title: 'The challengers',
                },
                {
                    id: 4,
                    imgUrl: 'wr_4.png',
                    title: 'The manipulators',
                },
                {
                    id: 5,
                    imgUrl: 'wr_5.png',
                    title: 'The interns'
                },
            ]
        };
    },
    methods: {
        next() {
            this.$refs.slick.next();
        },
        prev() {
            this.$refs.slick.prev();
        },
        changeItem(currentSlide) {
            this.index = currentSlide.currentTarget.slick.currentSlide;
            // console.log(currentSlide.currentTarget.slick.currentSlide);
        },
        reInit() {
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
            this.$nextTick(() => {
                this.$refs.c1.reSlick();
            });
            this.$nextTick(() => {
                this.$refs.c2.reSlick();
            });
        },
    },
    mounted() {
        this.c1 = this.$refs.c1;
        this.c2 = this.$refs.c2;
    },
}
</script>

<style lang="scss" scoped>
.section_3 {
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);

    .content_left {
        border-right: 0.5px solid rgba(0, 0, 0, 0.5);
        width: 65%;
        height: 100%;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
            padding: 55px 40px 40px 60px;
            height: 25%;

            h2 {
                font-family: 'Neboma Sporte';
                font-weight: bold;
                font-size: 62px;
                line-height: 71px;
                margin: 0;
            }

            p {
                font-size: 18px;
                line-height: 24px;
                margin: 0;
                width: 50%;
                text-align: right;
            }
        }

        .middle {
            padding: 20px;
            // border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
            height: 50%;
            display: flex;
            align-items: center;

            .slideWarrior {
                width: 80%;
                margin: auto;

                .imgSlide {
                    transition: all .5s ease;
                    padding: 60px 0;

                    img {
                        width: 100%;
                        transition: all .5s ease;
                        transform: scale(1);
                        cursor: pointer;
                        position: relative;
                    }
                }

                .slick-current {
                    .imgSlide {
                        padding: -10px;
                        position: relative;
                        z-index: 99;

                        img {
                            width: 100%;
                            transform: scale(1.3);
                        }
                    }
                }

            }
        }

        .bot {
            padding: 40px 60px;
            height: 25%;
            display: flex;
            align-items: center;
            position: relative;

            p {
                font-size: 18px;
                line-height: 24px;
                transition: all .5s ease;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 50px;

                &.show {
                    opacity: 1;
                    visibility: visible;
                    left: 0px;
                    top: 0;
                    position: relative;
                }
            }
        }
    }

    .content_right {
        width: 35%;
        height: 100%;
        padding-top: 87px;

        .title {
            display: none;
        }

        ul.menu {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-right: 60px;

            li {
                font-family: 'Neboma Sporte';
                font-size: 40px;
                line-height: 56px;
                text-align: right;
                color: rgba(0, 0, 0, 0.2);
                transition: all .5s ease;
                margin-bottom: 30px;
                cursor: pointer;

                &.active,
                &:hover {
                    color: #000000;
                }
            }
        }

        .sliderNav {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .titleMenu {
                padding-right: 60px;

                h2 {
                    font-family: 'Neboma Sporte';
                    font-size: 40px;
                    line-height: 56px;
                    text-align: right;
                    color: rgba(0, 0, 0, 0.2);
                    transition: all .5s ease;
                    margin-bottom: 30px;
                    cursor: pointer;

                    // white-space: nowrap;
                    @media(min-width: 993px) {
                        &:hover {
                            color: #000;
                            padding-right: 20px;
                        }
                    }
                }
            }

            .slick-current {
                .titleMenu {
                    h2 {
                        color: #000000;
                        padding-right: 20px;
                    }
                }
            }
        }
    }

    @media (max-width: 992px) {
        flex-direction: column-reverse;
        overflow: hidden;
        height: auto;
        border-bottom: 0;

        .content_left {
            width: 100%;
            border: 0;
            padding-top: 30px;

            .top {
                display: none;
            }

            .middle {
                height: auto;

                .slideWarrior {
                    width: 100%;
                    padding: 0 50px;

                    .imgSlide {
                        padding: 30px 0;
                    }

                    .slick-current {
                        .imgSlide {
                            padding: 0px;

                            img {
                                width: 100%;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }

            .bot {
                padding: 0 20px;
                margin-top: -30px;
            }
        }

        .content_right {
            width: 100%;
            padding-top: 0px;

            .title {
                border-top: 0.5px solid rgba(0, 0, 0, 0.5);
                display: block;
                width: 100%;
                padding: 0;
                padding-left: 20px;
                padding-top: 50px;
                margin-bottom: 50px;

                h2 {
                    margin: 0;
                    font-weight: bold;
                    font-family: 'Neboma Sporte';
                    font-size: 48px;
                }
            }

            .sliderNav {
                .titleMenu {
                    text-align: center;
                    padding-right: 0;
                    white-space: nowrap;

                    >h2 {
                        display: inline-block;
                        font-size: 26px;
                        line-height: 32px;
                        padding-right: 0 !important;
                        text-align: center;
                        margin-bottom: 20px !important;
                    }
                }
            }
        }
    }
}
</style>